import _ from "lodash";
import { cnt } from "../../constant.action";

// /** DEFAULT VARIABLES */

const INITIAL_PRODUCT_STATE = {
  getProduct: [],
  getProductByCo: [],
  getProductByCa: [],
  productDetail: {},
};

export const productDataRed = (state = INITIAL_PRODUCT_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case cnt.GET_PRODUCT:
      return { ...state, getProduct: payload };

    case cnt.GET_PRODUCT_BY_COMPANY:
      return { ...state, getProductByCo: payload };

    case cnt.GET_PRODUCT_BY_CATEGORY:
      return { ...state, getProductByCa: payload };

    case cnt.DETAIL_PRODUCT:
      return { ...state, productDetail: payload };

    case cnt.CLEAR_PRODUCT_DATA:
      return { ...INITIAL_PRODUCT_STATE };

    default:
      return state;
  }
};

export const getProductRed = (state = [], action) => {
  switch (action.type) {
    case cnt.GET_PRODUCT:
      return action.payload;
    default:
      return state;
  }
};

export const productDetailRed = (state = {}, action) => {
  switch (action.type) {
    case cnt.DETAIL_PRODUCT:
      return action.payload;
    default:
      return state;
  }
};

export const getProductByCoRed = (state = [], action) => {
  switch (action.type) {
    case cnt.GET_PRODUCT_BY_COMPANY:
      return action.payload;
    default:
      return state;
  }
};

export const getProductByCaRed = (state = [], action) => {
  switch (action.type) {
    case cnt.GET_PRODUCT_BY_CATEGORY:
      return action.payload;
    default:
      return state;
  }
};

const INITIAL_CART_STATE = {
  cartItems: [],
  shopsCount: [],
  totalPrice: 0,
};
export const shoppingCartRed = (state = INITIAL_CART_STATE, action) => {
  const { payload } = action;

  switch (action.type) {
    case cnt.ADD_TO_CART:
      // const { payload } = action;
      const itemId = state.cartItems.find(
        (product) => product.PR_ID === payload.PR_ID
      );

      if (itemId) {
        return {
          ...state,
          cartItems: state.cartItems.map((item) =>
            item.PR_ID === payload.PR_ID
              ? {
                  ...item,
                  QTY: item.QTY + 1,
                  subTotalProduct: parseFloat(item.PR_PRICE) * (item.QTY + 1),
                }
              : item
          ),
          totalPrice:
            parseFloat(state.totalPrice) + parseFloat(payload.PR_PRICE),
        };
      }

      /** final return */
      return {
        ...state,
        cartItems: [...state.cartItems, payload],
        totalPrice: parseFloat(state.totalPrice) + parseFloat(payload.PR_PRICE),
      };
    case cnt.REMOVE_FROM_CART:
      const count = state.shopsCount.filter(
        (shop) => shop.compId === payload.CO_ID
      );

      // console.log("this is new array: ", count);

      if (count[0].countCo > 1) {
        return {
          ...state,
          cartItems: state.cartItems.filter(
            (cartItem) => cartItem !== action.payload
          ),
          shopsCount: state.shopsCount.map((element) =>
            element.compId === payload.CO_ID
              ? {
                  ...element,
                  countCo: --element.countCo,
                }
              : element
          ),
          totalPrice:
            parseFloat(state.totalPrice) -
            parseFloat(payload.PR_PRICE) * payload.QTY,
        };
      }
      return {
        ...state,
        cartItems: state.cartItems.filter(
          (cartItem) => cartItem !== action.payload
        ),
        shopsCount: state.shopsCount.filter(
          (shop) => shop.compId !== payload.CO_ID
        ),
        totalPrice:
          parseFloat(state.totalPrice) -
          parseFloat(action.payload.PR_PRICE) * payload.QTY,
      };
    case cnt.CLEAR_CART:
      return { ...INITIAL_CART_STATE };
    case cnt.COMPLETE_PRODUCT:
      // const { payload } = action;
      return {
        ...state,
        cartItems: state.cartItems.map((item) =>
          item.PR_ID === action.payload.PR_ID
            ? {
                ...item,
                TRACK_STATUS: true,
              }
            : item
        ),
      };
    case cnt.INCOMPLETE_PRODUCT:
      // const { payload } = action;
      return {
        ...state,
        cartItems: state.cartItems.map((item) =>
          item.PR_ID === action.payload.PR_ID
            ? {
                ...item,
                TRACK_STATUS: false,
              }
            : item
        ),
      };
    case cnt.INCREASE_QTY:
      return {
        ...state,
        cartItems: state.cartItems.map((item) =>
          item.PR_ID === payload.PR_ID
            ? {
                ...item,
                QTY: item.QTY + 1,
                subTotalProduct: parseFloat(item.PR_PRICE) * (item.QTY + 1),
              }
            : item
        ),
        totalPrice: parseFloat(state.totalPrice) + parseFloat(payload.PR_PRICE),
      };
    case cnt.DECREASE_QTY:
      let currentQty = 0;

      return {
        ...state,
        cartItems: state.cartItems.map(function (item) {
          if (item.PR_ID === payload.PR_ID && item.QTY > 1) {
            currentQty = item.QTY;
            return {
              ...item,
              QTY: item.QTY - 1,
              subTotalProduct: parseFloat(item.PR_PRICE) * (item.QTY - 1),
            };
          } else {
            return item;
          }
        }),
        totalPrice:
          currentQty > 1
            ? parseFloat(state.totalPrice) - parseFloat(payload.PR_PRICE)
            : parseFloat(state.totalPrice),
      };
    case cnt.SHOPS_COUNT:
      if (
        state.shopsCount.filter((item) => item.compId === payload.CO_ID)
          .length < 1
      ) {
        return {
          ...state,
          shopsCount: [
            ...state.shopsCount,
            {
              compId: payload.CO_ID,
              compName: payload.CO_NAME,
              countCo: 1,
            },
          ],
        };
      } else {
        return {
          ...state,
          shopsCount: state.shopsCount.map((element) =>
            element.compId === payload.CO_ID
              ? {
                  ...element,
                  countCo: ++element.countCo,
                  // totalItem: Math.round((++element.countCo / state.cartItems.length) * 100),
                }
              : element
          ),
        };
      }
    case cnt.EMPTY_SHOPS_COUNT:
      return {
        ...state,
        shopsCount: [],
      };
    default:
      return state;
  }
};
