import _ from "lodash";
import { cnt } from "../../constant.action";

const INITIAL_COMPANY_STATE = {
  getCompany: [],
  companyDetail: {},
};

export const companyDataRed = (state = INITIAL_COMPANY_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case cnt.GET_COMPANY:
      return { ...state, getCompany: payload }; //{ ..._.mapKeys(action.payload, "CO_ID") };

    case cnt.DETAIL_COMPANY:
      return { ...state, companyDetail: payload };

    case cnt.CLEAR_COMPANY_DATA:
      return { ...INITIAL_COMPANY_STATE };

    default:
      return state;
  }
};
