import React from "react";
import { Navigate, Route, Switch } from "react-router-dom";

// -------------- PAGES IMPORTS --------------

import SignIn from "../compenents/auth/SignIn";
import SignUp from "../compenents/auth/SignUp";
import ActivateAccount from "../compenents/auth/ActivateAccount";
import Landing from "../compenents/ui-setup/Landing";

import Company from "../compenents/company/Company";
import NewCompany from "../compenents/company/NewCompany";
import EditCompany from "../compenents/company/EditCompany";

import Category from "../compenents/category/Category";
import NewCategory from "../compenents/category/NewCategory";
import EditCategory from "../compenents/category/EditCategory";

import Product from "../compenents/product/Product";
import NewProduct from "../compenents/product/NewProduct";
import EditProduct from "../compenents/product/EditProduct";
import ProductByCompany from "../compenents/product/ProductByCompany";
import ProductByCategory from "../compenents/product/ProductByCategory";
import Cart from "../compenents/product/Cart";
import EmptyCart from "../compenents/product/EmptyCart";
// import ToDoApp from "../compenents/toDo/ToDo";
import Terms from "../other_files/terms/Terms";
import PageNotFound from "../other_files/notFound/PageNotFound";
import ResponseSuccess from "../other_files/responseData/ResponseSuccess";
import ChangePassword from "../compenents/auth/ChangePassword";
import ResetPassword from "../compenents/auth/ResetPassword";
import ForgotPassword from "../compenents/auth/ForgotPassword";
import SavedCart from "../compenents/product/SavedCart";

import RouteGuard from "../compenents/layouts/RouteGuard";
export const RoutedContent = () => {
  return (
    <Switch>
      {/* ROOT PATH */}
      <Route path="/" component={SignIn} exact />

      {/* AUTH */}
      <Route path="/sign-in" component={SignIn} exact />
      {/* <Route path="/sign-up" component={SignUp} exact /> */}
      <Route path="/sign-up/:ref" component={SignUp} exact />
      <Route path="/activate-account" component={ActivateAccount} exact />
      <Route path="/change-password" component={ChangePassword} exact />
      <Route path="/reset-password/:id" component={ResetPassword} exact />
      <Route path="/forgot-password" component={ForgotPassword} exact />
      <Route path="/landing" component={Landing} exact />

      {/* COMPANY */}
      <RouteGuard path="/company" component={Company} exact />
      <RouteGuard path="/new-company" component={NewCompany} exact />
      <RouteGuard path="/edit-company" component={EditCompany} exact />

      {/* CATEGORY */}
      <RouteGuard path="/category" component={Category} exact />
      <RouteGuard path="/new-category" component={NewCategory} exact />
      <RouteGuard path="/edit-category" component={EditCategory} exact />

      {/* PRODUCT */}
      <RouteGuard path="/product" component={Product} exact />
      <RouteGuard path="/new-product" component={NewProduct} exact />
      <RouteGuard path="/edit-product" component={EditProduct} exact />
      <RouteGuard path="/company-product" component={ProductByCompany} exact />
      <RouteGuard
        path="/category-product"
        component={ProductByCategory}
        exact
      />
      <RouteGuard path="/cart" component={Cart} exact />
      <RouteGuard path="/saved-cart" component={SavedCart} exact />
      <RouteGuard path="/empty-cart" component={EmptyCart} exact />
      {/* <RouteGuard path="/toDo" component={ToDoApp} exact /> */}

      {/* TERMS AND CONDITION */}
      <Route path="/terms" component={Terms} exact />

      {/* OTHER ROUTE */}
      <RouteGuard path="/success-reponse" component={ResponseSuccess} />
      {/* NOT FOUND */}
      <RouteGuard path="*" component={PageNotFound} />
    </Switch>
  );
};
