import React, { useState } from "react";
import { connect } from "react-redux";
import Sidebar from "../ui-setup/Sidebar";
import Header from "../ui-setup/Header";
import { Link } from "react-router-dom";
import Hero from "../ui-setup/Hero";

import { useForm } from "react-hook-form";
import { cnt } from "../../services/constant.action";
import { apis as api } from "../../services/api.action";
import { BOM } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import { SubmitBtnLoad } from "../layouts/Facility";

const NewCategory = (props) => {
  
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { userData, history } = props;
  const { PROFILE_ID: profileId, token } = userData;
  const [isLoading, setIsLoading] = useState(false);

  const formSubmit = (formValues) => {
    setIsLoading(true);
    const body = {
      profileId,
      ...formValues,
      userToken: token,
    };

    // console.log(body);
    // return;

    BOM.FetchReqAction(body, api.NewCategory, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
        setIsLoading(false);
      } else {
        BOM.NotifyMsg(cnt.SUCCESS, res.message);
        history.push("/category");
        setIsLoading(false);
      }
    });
  };

  return (
    <div>
      {/* ========== HEADER ========== */}
      <Header />
      {/* ========== END HEADER ========== */}

      {/* ========== MAIN CONTENT ========== */}
      <main id="content" role="main">
        {/* Hero Section */}
        <Hero />
        {/* End Hero Section */}
        {/* Apps Section */}
        <div className="container space-bottom-2 space-bottom-lg-3">
          <div className="row">
            <Sidebar />
            <div className="col-lg-9 column-divider-lg">
              <div className="ml-lg-2">
                <form
                  onSubmit={handleSubmit(formSubmit)}
                >
                  <div className="border-bottom pb-7 mb-7">
                    {/* Title */}
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h3 className="mb-0">{appLabel.categoryInfo}</h3>
                      <Link to="/category" className="font-size-1 font-weight-bold" >
                      <i className="fas fa-arrow-circle-left fa-sm ml-1" /> {appLabel.back} 
                      </Link>
                    </div>
                    {/* End Title */}
                    {/* Billing Form */}
                    <div className="row">
                      <div className="col-md-6 mb-3 mb-sm-6">
                        {/* Input */}
                        <div className="js-form-message">
                          <label className="input-label">{appLabel.categoryName}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={appLabel.categoryName}
                            {...register("caName", {
                              required: appLabel.fieldReq,
                            })}
                          />
                          <span className="asterisks">
                      {errors?.caName?.message}
                    </span>
                        </div>
                        {/* End Input */}
                      </div>
                      <div className="col-md-6 mb-3 mb-sm-6">
                        {/* Input */}
                        <div className="js-form-message">
                          <label className="input-label">
                          {appLabel.description}
                            {appLabel.optional}
                          </label>
                          <textarea
                            className="form-control"
                            rows="4"
                            placeholder={appLabel.description}
                            {...register("caDesc")}
                          ></textarea>
                        </div>
                        {/* End Input */}
                      </div>
                    </div>
                    {/* End Form */}
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                  <SubmitBtnLoad
                      isLoading={isLoading}
                      btnClass={
                        "btn btn-primary btn-pill transition-3d-hover btn-sm"
                      }
                      btnName={appLabel.createBtn}
                      icon={"fas fa-arrow-circle-right fa-sm ml-1"}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* End Apps Section */}
      </main>
      {/* ========== END MAIN CONTENT ========== */}
    </div>
  );
};
const mapStateToProps = (state) => ({
  userData: state.auth.userData,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NewCategory);
