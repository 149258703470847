/**
 * @author Gedeon NdundeCode
 * @date 06/12/2021
 */

import { combineReducers } from "redux";
import auth from "../../redux/reducers/auth-red";

import { companyDataRed as companyData } from "../reducers/company-red";
import { categoryDataRed as categoryData } from "../reducers/category-red";

import {
  productDataRed as productData,
  shoppingCartRed as shoppingCart,
} from "../reducers/product-red";

import { staticDataRed as staticData } from "../reducers/staticData-red";

export default combineReducers({
  auth,
  companyData,
  categoryData,
  productData,
  shoppingCart,
  staticData,
});
