import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Sidebar from "../ui-setup/Sidebar";
import Header from "../ui-setup/Header";
import { BOM } from "../../services/defined.action";
import { cnt } from "../../services/constant.action";
import { Link } from "react-router-dom";
import Hero from "../ui-setup/Hero";
import Footer from "../ui-setup/Footer";
import { useForm } from "react-hook-form";
import appLabel from "../../config/appLabel";
import { apis as api } from "../../services/api.action";
import { SubmitBtnLoad } from "../layouts/Facility";


const EditCompany = (props) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const {location, userData, history} = props;
  const { state } = location;
  const { ggParam } = state;
  const { PROFILE_ID:profileId, token } = userData;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(()=>{
    getDetailById(ggParam)
    // console.log(props);
  },[])

  const getDetailById = (params) => {
    // BOM.LoadAlert(cnt.LOAD, "Processing");
    
    // setIsLoading(true);

    const body = {
      profileId,
      coId: params.CO_ID,
      userToken: token,
    };

    BOM.FetchReqAction(body, api.GetCompany, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let objDetail = res.companyInfoDetails;
        setValue("coName", objDetail.CO_NAME)
        setValue("coDesc", objDetail.CO_DESCRIPTION)
        setValue("coId", objDetail.CO_ID)
        // setProductData(objList)
        // setIsLoading(false);
      }
    });
  };


  const formSubmit = (formValues) => {
    setIsLoading(true);
    const body = {
      profileId,
      ...formValues,
      userToken: token,
    };

    // console.log(body);
    // return;
    // submit to action
    // NewCompanyAction(newCompanyBody);

    BOM.FetchReqAction(body, api.EditCompany, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
        setIsLoading(false);
      } else {
        BOM.NotifyMsg(cnt.SUCCESS, res.message);
        history.push("/company");
        setIsLoading(false);
      }
    });
  };

  return (
    <div>
      {/* ========== HEADER ========== */}
      <Header />
      {/* ========== END HEADER ========== */}

      {/* ========== MAIN CONTENT ========== */}
      <main id="content" role="main">
        {/* Hero Section */}
        <Hero />
        {/* End Hero Section */}
        {/* Apps Section */}
        <div className="container space-bottom-2 space-bottom-lg-3">
          <div className="row">
            <Sidebar />
            <div className="col-lg-9 column-divider-lg">
              <div className="ml-lg-2">
                <form
                  className="js-validate"
                  onSubmit={handleSubmit(formSubmit)}
                >
                  <div className="border-bottom pb-7 mb-7">
                    {/* Title */}
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h3 className="mb-0">Shop Information</h3>
                      <Link
                        to="/company"
                        className="font-size-1 font-weight-bold"
                      >
                        <i className="fas fa-arrow-circle-left fa-sm ml-1" />{" "}
                        Back
                      </Link>
                    </div>
                    {/* End Title */}
                    {/* Billing Form */}
                    <div className="row">
                      <div className="col-md-6 mb-3 mb-sm-6">
                        {/* Input */}
                        <div className="js-form-message">
                          <label className="input-label">
                            {appLabel.shopName}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={appLabel.shopName}
                            {...register("coName", {
                              required: appLabel.fieldReq,
                            })}
                          />
                          <span className="asterisks">
                            {errors?.coName?.message}
                          </span>
                        </div>
                        {/* End Input */}
                      </div>
                      <div className="col-md-6 mb-3 mb-sm-6">
                        {/* Input */}
                        <div className="js-form-message">
                          <label className="input-label">
                            {appLabel.description}
                            {appLabel.optional}
                          </label>
                          <textarea
                            className="form-control"
                            rows="4"
                            placeholder={appLabel.description}
                            {...register("coDesc")}
                          ></textarea>
                        </div>
                        {/* End Input */}
                      </div>
                    </div>
                    {/* End Form */}
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                  <SubmitBtnLoad
                      isLoading={isLoading}
                      btnClass={
                        "btn btn-primary btn-pill transition-3d-hover btn-sm"
                      }
                      btnName={appLabel.editBtn}
                      icon={"fas fa-arrow-circle-right fa-sm ml-1"}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* End Apps Section */}
      </main>
      {/* ========== END MAIN CONTENT ========== */}
      {/* ========== FOOTER ========== */}
      <Footer />
      {/* ========== END FOOTER ========== */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.auth.userData,
  };
};

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(EditCompany);
