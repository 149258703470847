import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Sidebar from "../ui-setup/Sidebar";
import Header from "../ui-setup/Header";
import Hero from "../ui-setup/Hero";

import { cnt } from "../../services/constant.action";
import { apis as api } from "../../services/api.action";
import { BOM } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import Notiflix from "notiflix";
import {handleSkeletonFac} from "../layouts/Facility"

const Category = (props) => {

  const { userData, history } = props;
  const { PROFILE_ID: profileId, token } = userData;
  const [categoryData, setCategoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getList();
  }, []);

  function getList() {
    // BOM.LoadAlert(cnt.LOAD, "Processing");
    setIsLoading(true);

    const body = {
      profileId,
      userToken: token,
    };

    BOM.FetchReqAction(body, api.GetCategory, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objList = res.categoryListing;

        setCategoryData(objList);
        setIsLoading(false);
      }
    });
  }
  
  const deleteItem = (objParam) => {
    const { CA_ID, CA_NAME } = objParam;

    let body = {
      profileId,
      itemId: CA_ID,
      itemType: 'category',
      userToken: token,
    };

    // console.log(body);
    // return

    const statusLabel = appLabel.deleteBtn + ' ' + CA_NAME;

    Notiflix.Confirm.show(
      "Are you sure ?",
      statusLabel,
      "Yes",
      "No",
      function () {
        // Yes
        BOM.FetchReqAction(body, api.DeleteItem, (err, res) => {
          if (err) {
            BOM.AlertMsg(cnt.DANGER, err);
          } else {
            BOM.NotifyMsg(cnt.SUCCESS, res.message);
            setCategoryData(categoryData.filter((item) => item.CA_ID !== CA_ID));
          }
        });
      },
      function () {
        // No button
      }
    );
  };

  const handleRedirectTo = (pathParam, objParam) => {
    history.push(`/${pathParam}`, {
      ggParam: objParam,
    });
  };
 

  const renderCategoryList = categoryData.map((item, index) => {
    return (
      <div className="col-sm-6 col-md-4 px-2 mb-3" key={index}>
        <div className="card card-frame h-100">
          <Link
            to={{
              pathname: "/category-product",
              state: {
                ggParam: item,
              },
            }}
            className="card-body"
          >
            <div className="media">
              <div className="avatar avatar-xs mt-1 mr-3">
                <img
                  className="avatar-img"
                  src="../../assets/img/nhouse/category.png"
                  alt="Image Description"
                />
              </div>
              <div className="media-body">
                <div className="d-flex align-items-center">
                  <span className="d-block text-dark font-weight-bold">
                    {item.CA_NAME.length > 12
                      ? item.CA_NAME.substring(0, 12) + "..."
                      : item.CA_NAME}
                  </span>
                </div>
                <small className="d-block text-body">
                  {" "}
                  {item.USER_TYPE}
                </small>
              </div>
            </div>
          </Link>
          <div className="action-icon">
            <i
              className="fas fa-pen-square text-info"
               onClick={() => handleRedirectTo("edit-category", item)}
            />
            <i
              className="fas fa-times-circle text-danger"
              onClick={() => deleteItem(item)}
            />
          </div>
        </div>
      </div>
    );
  });
  return (
    <div>
      {/* ========== HEADER ========== */}
      <Header />
      {/* ========== END HEADER ========== */}

      {/* ========== MAIN CONTENT ========== */}
      <main id="content" role="main">
        {/* Hero Section */}
        <Hero />
        {/* End Hero Section */}
        {/* Apps Section */}
        <div className="container space-bottom-2 space-bottom-lg-3">
          <div className="row">
            <Sidebar />
            <div className="col-lg-9 column-divider-lg">
              <div className="ml-lg-2">
                {/* Title */}
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h3 className="mb-0">
                    {categoryData.length > 1 ? "My Categories" : "My Category"}
                  </h3>
                  <a className="font-size-1 font-weight-bold">
                    {categoryData.length > 1
                      ? categoryData.length + " Items"
                      : categoryData.length + " Item"}{" "}
                    {/* <i className="fas fa-angle-right fa-sm ml-1" /> */}
                  </a>
                </div>
                {/* End Title */}
                <div className="row mx-n2 mb-7">
                  {/* STATIC ADD CARD */}
                  <div className="col-sm-6 col-md-4 px-2 mb-3">
                    <div className="card card-frame h-100">
                      <Link
                        to="/new-category"
                        className="card-body"
                        href="app-description.html"
                      >
                        <div className="media">
                          <div className="avatar avatar-xs mt-1 mr-3">
                            <i className="fas fa-plus-circle fa-2x text-warning specificIcon" />
                          </div>
                          <div className="media-body">
                            <div className="d-flex align-items-center">
                              <span className="d-block text-dark font-weight-bold">
                                {appLabel.addNewBtn}
                              </span>
                            </div>
                            <small className="d-block text-body">&nbsp;</small>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                  {/* Card */}
                  {isLoading ? handleSkeletonFac() : renderCategoryList}
                  {/* End Card */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Apps Section */}
      </main>
      {/* ========== END MAIN CONTENT ========== */}
    </div>
  );
};
const mapStateToProps = (state) => ({
  userData: state.auth.userData,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Category);