import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { cnt } from "../../services/constant.action";
import { BOM } from "../../services/defined.action";
import { ActivateAccountAction } from "../../services/redux/actions/auth-act";

const ActivateAccount = (props) => {
  /** INITIALIZE VARIABLES */
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [gender, setGender] = useState("");
  const { ActivateAccountAction, auth, userData, history } = props;

  useEffect(() => {
    if (!auth.isSignedIn) {
      history.push("/sign-in");
      return;
    }
  }, []);

  /** SUBMISSION */
  const onActivateUp = (fname, lname, gender) => {
    if (!fname) {
      BOM.AlertMsg(cnt.WARNING, "First Name field is required!");
      return;
    }

    if (!lname) {
      BOM.AlertMsg(cnt.WARNING, "Last Name field is required!");
      return;
    }

    if (!gender) {
      BOM.AlertMsg(cnt.WARNING, "Gender field is required!!");
      return;
    }

    if (fname.length < 3) {
      BOM.AlertMsg(cnt.WARNING, "First Name must be more than 3 characters!");
      return;
    }

    if (lname.length < 3) {
      BOM.AlertMsg(cnt.WARNING, "Last Name must be more than 3 characters!");
      return;
    }

    ActivateAccountAction({
      fname:BOM.RmvAccentOnly(fname),
      lname:BOM.RmvAccentOnly(lname),
      gender,
      profileId: userData.PROFILE_ID,
    });
  };

  return (
    <div>
      <div>
        {/* ========== MAIN ========== */}
        <main id="content" role="main">
          {/* Form */}
          <div className="d-flex align-items-center position-relative vh-lg-100">
            <div className="container">
              <div className="row no-gutters">
                <div className="col-md-8 col-lg-7 col-xl-6 offset-md-2 offset-lg-2 offset-xl-3 space-top-3 space-lg-0">
                  {/* Form */}
                  <form onSubmit={(e) => e.preventDefault()}>
                    {/* Title */}
                    <div className="mb-5 mb-md-7">
                      <h1 className="h3">Hi {userData.USERNAME}</h1>
                      <h2 className="h4">You're Almost There</h2>
                      <p>Just one more step to get started.</p>
                    </div>
                    {/* End Title */}
                    {/* Form Group */}
                    <div className="js-form-message form-group">
                      <label className="input-label">Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last Name"
                        onChange={(e) => setLname(e.target.value)}
                      />
                    </div>
                    {/* End Form Group */}
                    {/* Form Group */}
                    <div className="js-form-message form-group">
                      <label className="input-label">First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="First Name"
                        onChange={(e) => setFname(e.target.value)}
                      />
                    </div>
                    {/* End Form Group */}
                    {/* Form Group */}
                    <div className="js-form-message form-group">
                      <label className="input-label">Gender</label>
                      <select
                        className="form-control custom-select"
                        onChange={(e) => setGender(e.target.value)}
                      >
                        <option>Choose...</option>
                        <option value={"MALE"}>Male</option>
                        <option value={"FEMALE"}>Female</option>
                      </select>
                    </div>
                    {/* End Form Group */}
                    {/* Button */}
                    <div className="row align-items-left mb-5">
                      <div className="col-sm-3 text-sm-right">
                        <button
                          type="submit"
                          className="btn btn-primary transition-3d-hover btn-sm"
                          onClick={() => onActivateUp(fname, lname, gender)}
                        >
                          <i className="fas fa-arrow-circle-right"></i>&nbsp;NEXT
                        </button>
                      </div>
                    </div>
                    {/* End Button */}
                  </form>
                  {/* End Form */}
                </div>
              </div>
            </div>
          </div>
          {/* End Form */}
        </main>
        {/* ========== END MAIN ========== */}
      </div>
    </div>
  );
};
const mapStateProps = (state) => {
  return { auth: state.auth, userData: state.auth.userData };
};
export default connect(mapStateProps, { ActivateAccountAction })(
  ActivateAccount
);
