/**
 * @author Gedeon NdundeCode
 * @date 06/12/2021
 */
import Auth, { headerOptions as options, apis as api } from "../../api.action";
import {BOM} from "../../defined.action";
import history from "../../../history";
import { cnt } from "../../constant.action";
import Notiflix from "notiflix";

export const GetCountryAction = (body) => {
  // LoadAlert(cnt.LOAD, "Processing");
  try {
    return async (dispatch) => {
      const res = await Auth.post(api.GetCountry, body, options);

      const response = res.data;
      switch (response.status.toLowerCase()) {
        case "ok":
          dispatch({
            type: cnt.COUNTRY_DATA,
            payload: response.countryListing,
          });
          BOM.AlertMsg();
          break;
        case "forbidden":
          BOM.AlertMsg(cnt.WARNING, response.message);
          history.push("/sign-in");
          break;
        default:
          BOM.AlertMsg(cnt.DANGER, response.message);
          break;
      }
    };
  } catch (error) {
    console.log("error: ", error);
    BOM.AlertMsg(cnt.DANGER, "Something went wrong!");
  }
};
