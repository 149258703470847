import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import appLabel from "../../config/appLabel";
import NumberFormat from "react-number-format";

export const SubmitBtnLoad = (props) => {
  const { isLoading, btnName, icon, btnClass } = props;

  return (
    <>
      {/* <button
        type="submit"
        className={`btn-sm btn btn-${isLoading ? "white" : "primary"} ${
          isFullWidth ? "block full-width" : ""
        } `}
        style={{
          marginTop: isFullWidth && 30,
          boxShadow: isFullWidth && "0 10px 6px -6px #fff",
        }}
      >
        {isLoading && (
          <span style={{ color: "#000", fontWeight: "600" }}>
            <i className="fa fa-spinner fa-spin" /> &nbsp; {appLabel.processing}
          </span>
        )}
  
        {!isLoading && (
          <span>
            <i className={icon} /> &nbsp; {btnName}
          </span>
        )}
      </button> */}

      <button
        type="submit"
        className={
          btnClass ? btnClass : "btn btn-primary transition-3d-hover btn-sm"
        }
        disabled={isLoading}
      >
        {isLoading && (
          <span>
            <i className="fas fa-spinner fa-spin" /> &nbsp;{" "}
            {appLabel.processing}
          </span>
        )}

        {!isLoading && (
          <span>
            <i className={icon} /> &nbsp; {btnName}
          </span>
        )}
      </button>
    </>
  );
};

export const handleSkeletonFac = () =>
  Array(4)
    .fill()
    .map((el, elIndex) => (
      <div key={elIndex} className="col-sm-6 col-md-4 px-2 mb-3">
        <div className="card card-frame h-100">
          <a className="card-body">
            <div className="media">
              <div className="avatar avatar-xs mt-1 mr-3 align-items-center">
                <Skeleton circle={true} height={35} width={35} />
              </div>
              <div className="media-body">
                <div className="d-flex align-items-center">
                  <span className="d-block text-dark font-weight-bold">
                    <Skeleton />
                  </span>
                </div>
                <small className="d-block text-body">
                  <Skeleton />
                  <Skeleton />
                </small>
              </div>
            </div>
          </a>
          <div className="action-icon">
            <a className="btn btn-xs btn-white mr-1 mb-2">
              <Skeleton height={14} width={20} />
            </a>
            <a className="btn btn-xs btn-white mb-2">
              <Skeleton height={14} width={20} />
            </a>
          </div>
        </div>
      </div>
    ));
export const handleSkeletonProdFac = () =>
  Array(4)
    .fill()
    .map((el, elIndex) => (
      <div
        key={elIndex}
        className="col-sm-6 col-lg-4 px-2 px-sm-3 mb-3 mb-sm-5"
      >
        <div className="card border shadow-none text-center h-100">
          <div className="position-relative">
            <div className="position-absolute top-0 right-0 pt-3 pr-3">
              <a className="btn btn-xs btn-white mr-1 mb-2">
                <Skeleton height={14} width={20} />
              </a>
            </div>
            <div className="position-absolute top-0 right-0 pt-3 pr-3">
              <a className="btn btn-xs btn-white mr-1 mb-2">
                <Skeleton height={14} width={20} />
              </a>
            </div>
          </div>
          <div className="card-body pt-4 px-4 pb-0">
            <div className="mb-2">
              <a
                className="d-inline-block text-body small font-weight-bold mb-1"
                href="#"
              >
                <Skeleton />
              </a>
              <span className="d-block font-size-1">
                <a className="text-inherit">
                  <Skeleton />
                </a>
              </span>
              <div className="d-block">
                <span className="text-dark font-weight-bold">
                  <Skeleton />
                </span>
              </div>
            </div>
          </div>
          <div className="card-footer border-0 pt-0 pb-4 px-4">
            <div className="mb-3">
              <a className="d-inline-flex align-items-center small" href="#">
                <span className="text-warning font-weight-bold">
                  <Skeleton />
                </span>
              </a>
            </div>
            <a className="btn btn-xs btn-white mr-1 mb-2">
              <Skeleton height={14} width={20} />
            </a>
          </div>
        </div>
      </div>
    ));
export const AmntFormatFac = (props) => {
  const { inputValue, currency } = props;

  return (
    <NumberFormat
      value={inputValue}
      displayType="text"
      thousandSeparator={true}
      prefix={currency ? currency + " " : ""}
    />
  );
};
