import React, { useEffect } from "react";

const Terms = (props) => {
  const { history } = props;
  // const closeWindow = () => {
  //   window.close();
  // };
  useEffect(() => {
    // console.log(props);
  }, []);
  return (
    <div>
      {/* ========== MAIN ========== */}
      <main id="content" role="main">
        {/* Hero Section */}
        <div
          className="bg-img-hero"
          style={{
            backgroundImage:
              "url(../../assets/svg/components/abstract-shapes-12.svg)",
          }}
        >
          <div className="container space-top-3 space-top-lg-4 space-bottom-2 position-relative z-index-2">
            <div className="w-md-80 w-lg-60 text-center mx-md-auto">
              <h1>
                <span className="text-success">nHouse</span> Terms &amp;
                Conditions
              </h1>
              <p>Effective date: 1 January 2022</p>
              <p
                onClick={() => history.goBack()}
                className="text-danger"
                style={{ cursor: "pointer" }}
              >
                {" "}
                <i className="fas fa-arrow-circle-left"></i> &nbsp;Back to Sign
                up
              </p>
            </div>
          </div>
        </div>
        {/* End Hero Section */}
        {/* Terms Content Section */}
        <div className="container space-2 space-bottom-lg-3">
          <div className="row">
            <div
              id="stickyBlockStartPoint"
              className="col-md-4 col-lg-3 mb-7 mb-md-0"
            >
              {/* Nav */}
              <nav
                className="js-sticky-block card shadow-none bg-light"
                data-hs-sticky-block-options='{
           "parentSelector": "#stickyBlockStartPoint",
           "targetSelector": "#logoAndNav",
           "breakpoint": "md",
           "startPoint": "#stickyBlockStartPoint",
           "endPoint": "#stickyBlockEndPoint",
           "stickyOffsetTop": 24,
           "stickyOffsetBottom": 24
         }'
              >
                <div className="card-body">
                  <ul className="js-scroll-nav nav nav-sm nav-x-0 flex-column">
                    <li className="nav-item">
                      <a
                        className="nav-link font-weight-bold mb-2"
                        href="#services"
                      >
                        1. Using our services
                      </a>
                      <ol className="navbar-nav">
                        <li className="nav-item">
                          <a className="nav-link mb-2" href="#personal-data">
                            A. Personal Data that we collect about you
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link mb-2" href="#information">
                            B. Information that we collect automatically on our
                            Sites
                          </a>
                        </li>
                      </ol>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link font-weight-bold mb-2"
                        href="#privacy"
                      >
                        2. Privacy and copyright protection
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link font-weight-bold mb-2"
                        href="#yourContent"
                      >
                        3. Your content in our services
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
              {/* End Nav */}
            </div>
            <div className="col-md-8 col-lg-9">
              <div id="intro" className="space-bottom-1">
                {/* Title */}
                <div className="mb-3">
                  <h2 className="h3">Welcome to Front</h2>
                </div>
                {/* End Title */}
                <p>
                  Thanks for using our products and services. The Services are
                  provided by{" "}
                  <a target={"_blank"} href="https://nacomoda.com">
                    nAcomoda
                  </a>{" "}
                  Ltd.
                  {/* Reg No 2021/123770/07, located in South Africa. */}
                </p>
                <p>
                  By using our Services, you are agreeing to these terms. Please
                  read them carefully.
                </p>
                <p>
                  Our Services are very diverse, so sometimes additional terms
                  or product requirements (including age requirements) may
                  apply. Additional terms will be available with the relevant
                  Services, and those additional terms become part of your
                  agreement with us if you use those Services.
                </p>
              </div>
              <div id="services" className="space-bottom-1">
                {/* Title */}
                <div className="mb-3">
                  <h3>1. Using our services</h3>
                </div>
                {/* End Title */}
                <p>
                  You must follow any policies made available to you within the
                  Services.
                </p>
                <p>
                  Don't misuse our Services. For example, don't interfere with
                  our Services or try to access them using a method other than
                  the interface and the instructions that we provide. You may
                  use our Services only as permitted by law, including
                  applicable export and re-export control laws and regulations.
                  We may suspend or stop providing our Services to you if you do
                  not comply with our terms or policies or if we are
                  investigating suspected misconduct.
                </p>
                <p>
                  Using our Services does not give you ownership of any
                  intellectual property rights in our Services or the content
                  you access. You may not use content from our Services unless
                  you obtain permission from its owner or are otherwise
                  permitted by law. These terms do not grant you the right to
                  use any branding or logos used in our Services. Don't remove,
                  obscure, or alter any legal notices displayed in or along with
                  our Services.
                </p>
                {/* Title */}
                <div id="personal-data" className="mb-3">
                  <h4>A. Personal Data that we collect about you.</h4>
                </div>
                {/* End Title */}
                <p>
                  Personal Data is any information that relates to an identified
                  or identifiable individual. The Personal Data that you provide
                  directly to us through our Sites will be apparent from the
                  context in which you provide the data. In particular:
                </p>
                <ul>
                  <li className="pb-2">
                    When you register for a{" "}
                    <b className="text-success">nHouse</b> account we collect
                    your full name, email address, and account log-in
                    credentials.
                  </li>
                  <li className="pb-2">
                    When you fill-in our online form to contact our sales team,
                    we collect your full name, work email, country, and anything
                    else you tell us about your project, needs and timeline.
                  </li>
                </ul>
                <p>
                  When you respond to <b className="text-success">nHouse</b>{" "}
                  emails or surveys we collect your email address, name and any
                  other information you choose to include in the body of your
                  email or responses. If you contact us by phone, we will
                  collect the phone number you use to call{" "}
                  <b className="text-success">nHouse</b>. If you contact us by
                  phone as a <b className="text-success">nHouse</b> User, we may
                  collect additional information in order to verify your
                  identity.
                </p>
                {/* Title */}
                <div id="information" className="mb-3">
                  <h4>
                    B. Information that we collect automatically on our Sites.
                  </h4>
                </div>
                {/* End Title */}
                <p>
                  We also may collect information about your online activities
                  on websites and connected devices over time and across
                  third-party websites, devices, apps and other online features
                  and services. We use Google Analytics on our Sites to help us
                  analyze Your use of our Sites and diagnose technical issues.
                </p>
                <p>
                  To learn more about the cookies that may be served through our
                  Sites and how You can control our use of cookies and
                  third-party analytics, please see our Cookie Policy.
                </p>
              </div>
              <div id="privacy" className="space-bottom-1">
                {/* Title */}
                <div className="mb-3">
                  <h3>2. Privacy and copyright protection</h3>
                </div>
                {/* End Title */}
                <p>
                  <b className="text-success">nHouse</b>'s privacy policies
                  explain how we treat your personal data and protect your
                  privacy when you use our Services. By using our Services, you
                  agree that <b className="text-success">nHouse</b> can use such
                  data in accordance with our privacy policies.
                </p>
                <p>
                  We respond to notices of alleged copyright infringement and
                  terminate accounts of repeat infringers.
                </p>
                <p>
                  We provide information to help copyright holders manage their
                  intellectual property online. If you think somebody is
                  violating your copyrights and want to notify us, you can find
                  information about submitting notices and{" "}
                  <b className="text-success">nHouse</b>'s policy about
                  responding to notices in <a href="#">our Help Center</a>.
                </p>
              </div>
              <div id="yourContent">
                {/* Title */}
                <div className="mb-3">
                  <h3>3. Your content in our services</h3>
                </div>
                {/* End Title */}
                <p>
                  Some of our Services allow you to upload, submit, store, send
                  or receive content. You retain ownership of any intellectual
                  property rights that you hold in that content. In short, what
                  belongs to you stays yours.
                </p>
                <p>
                  When you upload, submit, store, send or receive content to or
                  through our Services, you give{" "}
                  <b className="text-success">nHouse</b> (and those we work
                  with) a worldwide license to use, host, store, reproduce,
                  modify, create derivative works (such as those resulting from
                  translations, adaptations or other changes we make so that
                  your content works better with our Services), communicate,
                  publish, publicly perform, publicly display and distribute
                  such content. The rights you grant in this license are for the
                  limited purpose of operating, promoting, and improving our
                  Services, and to develop new ones. This license continues even
                  if you stop using our Services (for example, for a business
                  listing you have added to{" "}
                  <b className="text-success">nHouse</b> Maps). Some Services
                  may offer you ways to access and remove content that has been
                  provided to that Service. Also, in some of our Services, there
                  are terms or settings that narrow the scope of our use of the
                  content submitted in those Services. Make sure you have the
                  necessary rights to grant us this license for any content that
                  you submit to our Services.
                </p>
              </div>
              <div>
                <p
                  onClick={() => history.goBack()}
                  className="text-danger"
                  style={{ cursor: "pointer" }}
                >
                  {" "}
                  <i className="fas fa-arrow-circle-left"></i> &nbsp;Back to
                  Sign up
                </p>
              </div>
              {/* Sticky Block End Point */}
              <div id="stickyBlockEndPoint" />
            </div>
          </div>
        </div>
        {/* End Terms Content Section */}
      </main>
      {/* ========== END MAIN ========== */}
    </div>
  );
};

export default Terms;
