import axios from "axios";

import appConfig from "../config/appConfig.json";

const { dev, prod, isProduction, apiSettings } = appConfig;

/** DEFINED METHOD */
const isProductionUrl = (key) => {
  switch (key) {
    case true:
      return prod.baseURL;
    case false:
      return dev.baseURL;
  }
};
/** LIVE SERVICE */
export default axios.create({
  baseURL: isProductionUrl(isProduction),
});

/** HEADER */
export const headerOptions = {
  headers: apiSettings.headers,
};


/** APIS CONSTANT */
export const apis = {
  /** AUTH */
  Login: "login",
  Signup: "signup",
  ActivateAccount: "activateAccount",
  ChangePassword: "changePassword",
  ForgotPassword: "forgotPassword",
  ResetPassword: "resetPassword",

  /** COMPANY */
  GetCompany: "getCompany",
  NewCompany: "newCompany",
  EditCompany: "editCompany",
  DeleteCompany: "deleteCompany",

  /** CATEGORY */
  GetCategory: "getCategory",
  NewCategory: "newCategory",
  EditCategory: "editCategory",
  DeleteCategory: "deleteCategory",

  /** PRODUCT */
  NewProduct: "newProduct",
  GetProduct: "getProduct",
  GetProductByCompany: "getProductByCompany",
  GetProductByCategory: "getProductByCategory",
  EditProduct: "editProduct",
  DeleteProduct: "deleteProduct",

  /** COUNTRY */
  GetCountry: "getCountry",

  /** CART */
  GetCart: "getCart",
  NewCart: "newCart",
  GetSavedCart: "getSavedCart",

   // GLOBAL DELETE
   DeleteItem: "deleteItem",
};
