import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import {
  ShoppingCartAction,
  SaveCartAction,
} from "../../services/redux/actions/product-act";
import Header from "../ui-setup/Header";
import { cnt } from "../../services/constant.action";
import { BOM } from "../../services/defined.action";
import Footer from "../ui-setup/Footer";
import { SubmitBtnLoad } from "../layouts/Facility";
import appLabel from "../../config/appLabel";


const Cart = (props) => {
  const {
    auth,
    history,
    shoppingData,
    shopsCountData,
    cartTotal,
    ShoppingCartAction,
    userData,
    SaveCartAction,
  } = props;
  // const [percentageArray]

  useEffect(() => {
    if (!auth.isSignedIn) {
      history.push("/sign-in");
      return;
    }

    /** check cart data */
    if (shoppingData.length === 0) {
      history.push("/empty-cart");
      return;
    }

    /** EMPTY FIRST SHOPCOUNT ARRAY THEN CALL CARTITEMS TO GET CURRENT DATA */
    ShoppingCartAction({}, "empty_shop_count");
    setTimeout(() => {
      shoppingData.map((item) => ShoppingCartAction(item, "shopcount"));
    }, 2000);

    // console.log(userData);
  }, []);

  const calculatePercentage = (noOfProductByCo, array) => {
    // console.log(array);
    let totalItem = array.length;

    return Math.round((noOfProductByCo / totalItem) * 100);
  };

  const renderShopArray = shopsCountData.map((item) => {
    return (
      <div className="card shadow-none mb-3" key={item.compId}>
        <div className="card-body p-0">
          <div className="custom-control custom-radio d-flex align-items-center small">
            {/* <label className="custom-control-label ml-1"> */}
            <label>
              <span className="d-block font-size-1 font-weight-bold mb-1">
                <span className="badge badge-primary badge-pill ml-2">
                  {" "}
                  {calculatePercentage(item.countCo, shoppingData)} %{" "}
                </span>{" "}
                &nbsp;
                {item.compName.length > 12
                  ? item.compName.substring(0, 12) + "..."
                  : item.compName}
              </span>
              <span className="d-block text-muted">
                {item.countCo > 1
                  ? // ? item.countCo + " products for the above shop."
                    item.countCo + " products."
                  : item.countCo + " product."}{" "}
              </span>
            </label>
          </div>
        </div>
      </div>
    );
  });

  const renderShoppingCart = shoppingData.map((item) => {
    // setQuantity(item.QTY);
    return (
      <div className="border-bottom pb-5 mb-5" key={item.TRACK_ID}>
        <div className="media">
          <div className="avatar avatar-xs mt-1 mr-3">
            <img
              className="avatar-img"
              src="../../assets/img/nhouse/brand-identity_3.png"
              alt="Image Description"
            />
          </div>
          <div className="media-body">
            <div className="row">
              <div
                className="col-md-7 mb-3 mb-md-0"
                style={{
                  textDecoration: item.TRACK_STATUS ? "line-through" : "",
                  textDecorationColor: item.TRACK_STATUS ? "red" : "",
                }}
              >
                <a
                  className="h5 d-block"
                  style={{
                    textDecoration: item.TRACK_STATUS ? "line-through" : "",
                    textDecorationColor: item.TRACK_STATUS ? "red" : "",
                  }}
                >
                  {item.PR_NAME}
                </a>
                <div className="d-block d-md-none">
                  <span className="h5 d-block mb-1">
                    {
                      <NumberFormat
                        value={item.PR_PRICE * item.QTY}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={userData.CUR_CODE + " "}
                      />
                    }
                  </span>
                </div>
                <div className="text-body font-size-1 mb-1">
                  <span>{item.CA_NAME}</span>
                </div>
                <div className="text-body font-size-1 mb-1">
                  <span>
                    {" "}
                    {userData.CUR_CODE} {item.PR_PRICE + " x " + item.QTY}
                  </span>
                </div>
                <div className="text-body font-size-1 mb-1">
                  {/* <span>Company : </span> */}
                  <span>{item.CO_NAME}</span>
                </div>
              </div>
              <div className="col-md-3">
                <div className="row">
                  <div className="col-auto">
                    {/* Quantity */}
                    <div className="border rounded py-2 px-3 mb-3">
                      <div className="js-quantity-counter row align-items-center">
                        <div className="col-6">
                          {/* <small className="d-block text-body font-weight-bold">Select quantity</small> */}
                          <input
                            className="js-result form-control h-auto border-0 rounded p-0"
                            type="text"
                            disabled
                            placeholder={item.QTY}
                          />
                        </div>
                        <div className="col-6 text-right spaceBetween">
                          <a
                            className="js-minus btn btn-xs btn-icon btn-outline-secondary rounded-circle"
                            onClick={() => ShoppingCartAction(item, "decrease")}
                          >
                            <i className="fas fa-minus" />
                          </a>
                          <a
                            className="js-plus btn btn-xs btn-icon btn-outline-secondary rounded-circle"
                            onClick={() => ShoppingCartAction(item, "increase")}
                          >
                            <i className="fas fa-plus" />
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* End Quantity */}
                  </div>
                  <div className="col-auto">
                    <Link
                      to="/cart"
                      className="d-block text-body font-size-1 mb-1"
                      onClick={() => ShoppingCartAction(item, "remove")}
                    >
                      <i className="far fa-trash-alt text-hover-primary mr-1" />
                      <span className="font-size-1 text-hover-primary">
                        Remove
                      </span>
                    </Link>

                    <Link
                      to="/cart"
                      className="d-block text-body font-size-1 mb-1"
                      onClick={() =>
                        ShoppingCartAction(
                          item,
                          item.TRACK_STATUS ? "incomplete" : "complete"
                        )
                      }
                    >
                      <i
                        className={
                          item.TRACK_STATUS
                            ? "fas fa-check text-success mr-1"
                            : ""
                        }
                      />
                      <span
                        className={
                          item.TRACK_STATUS
                            ? "font-size-1 text-success"
                            : "text-hover-primary"
                        }
                      >
                        {item.TRACK_STATUS ? "Completed" : "Mark as completed"}
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-4 col-md-2 d-none d-md-inline-block text-right">
                <span className="h5 d-block mb-1">
                  {
                    <NumberFormat
                      value={item.PR_PRICE * item.QTY}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={userData.CUR_CODE + " "}
                    />
                  }
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });

  const onSaveCart = (cartItems, cartTotal) => {
    // console.log(cartItems);
    let found = false;
    let itemIncomplete = "";

    /** CHECK FOR ALL MARK DONE */
    // cartItems.map((item) => {
    //   if (item.TRACK_STATUS === false) {
    //     found = true;
    //     itemIncomplete = item.PR_NAME
    //   }
    // });
    for (var index = 0; index < cartItems.length; index++) {
      const element = cartItems[index];

      if (element.TRACK_STATUS === false) {
        found = true;
        itemIncomplete = element.PR_NAME;
        break;
      }
    }
    if (found) {
      BOM.AlertMsg(
        cnt.WARNING,
        itemIncomplete.toUpperCase() +
          " item should be mark as completed before saving the cart!"
      );
      // BOM.AlertMsg(cnt.WARNING, "All Items should be mark as completed before saving!");
      return;
    }

    SaveCartAction({
      profileId: userData.PROFILE_ID,
      cartItems,
      cartTotal,
      countryId: userData.COUNTRY_ID,
      userToken: userData.token,
    });
  };
  return (
    <div>
      {/* ========== HEADER ========== */}
      <Header />
      {/* ========== END HEADER ========== */}

      {/* ========== MAIN CONTENT ========== */}
      <main id="content" role="main">
        {/* Cart Section */}
        <div className="container space-1 space-md-2">
          <div className="row">
            <div className="col-lg-8 mb-7 mb-lg-0">
              {/* Title */}
              <div className="d-flex justify-content-between align-items-end border-bottom pb-3 mb-7">
                <h1 className="h6 mb-0">
                  {" "}
                  <Link to="/saved-cart">
                    <i className="fas fa-arrow-circle-left fa-xs mr-1" />
                  </Link>{" "}
                  {appLabel.myCart}{" "}
                </h1>
                <span>
                  {shoppingData.length > 1
                    ? shoppingData.length + " items"
                    : shoppingData.length + " item"}{" "}
                </span>
                <h1 className="h6 mb-0">
                <Link to="/product">
                    <i className="fas fa-arrow-circle-left fa-sm ml-1" /> 
                  </Link>{" "}
                  {appLabel.productList}
                </h1>
              </div>
              {/* End Title */}
              <form>
                {/* Product Content */}
                {renderShoppingCart}
                {/* End Product Content */}
                <div className="d-sm-flex justify-content-end">
                  <Link to="/product" className="font-weight-bold">
                    <i className="fas fa-arrow-circle-left fa-xs mr-1" />
                    {appLabel.continueAdding}
                  </Link>
                </div>
              </form>
            </div>
            <div className="col-lg-4">
              <div className="pl-lg-4">
                {/* Order Summary */}
                <div className="card shadow-soft p-4 mb-4">
                  {/* Title */}
                  <div className="border-bottom pb-4 mb-4">
                    <h2 className="h3 mb-0">Cart summary</h2>
                    <div className="media align-items-center">
                      <span className="d-block font-size-1 mr-3">
                        No of Shop
                      </span>
                      <div className="media-body text-right">
                        <span className="text-dark font-weight-bold">
                          {shopsCountData.length !== 0
                            ? shopsCountData.length
                            : ""}
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* End Title */}
                  <div className="border-bottom pb-4 mb-4">
                    {/* Checkbox */}
                    {renderShopArray.length !== 0
                      ? renderShopArray
                      : "Loading shops...!"}
                    {/* End Checkbox */}
                  </div>
                  <div className="media align-items-center mb-3">
                    <span className="d-block font-size-1 mr-3">Total</span>
                    <div className="media-body text-right">
                      <span className="text-dark font-weight-bold">
                        {
                          <NumberFormat
                            value={cartTotal}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={userData.CUR_CODE + " "}
                          />
                        }
                      </span>
                    </div>
                  </div>

                  <div className="row mx-1">
                    <div className="col px-1 my-1">
                      <a
                        onClick={() => onSaveCart(shoppingData, cartTotal)}
                        className="btn btn-primary text-white btn-xs btn-block btn-pill transition-3d-hover"
                      >
                        {appLabel.saveCart}
                      </a>
                    </div>
                    <div className="col px-1 my-1">
                      {shoppingData.length !== 0 ? (
                        <a
                          className="btn btn-danger text-white btn-xs  btn-block btn-pill transition-3d-hover"
                          onClick={() => ShoppingCartAction({}, "clear")}
                        >
                          {appLabel.clearCart}
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                {/* End Order Summary */}
              </div>
            </div>
          </div>
        </div>
        {/* End Cart Section */}
      </main>
      {/* ========== END MAIN CONTENT ========== */}
      {/* ========== FOOTER ========== */}
      <Footer />
      {/* ========== END FOOTER ========== */}
    </div>
  );
};

const mapStateProps = (state) => {
  // console.log(state.shoppingCart);
  return {
    auth: state.auth,
    userData: state.auth.userData,
    shoppingData: state.shoppingCart.cartItems,
    shopsCountData: state.shoppingCart.shopsCount,
    cartTotal: state.shoppingCart.totalPrice,
  };
};
export default connect(mapStateProps, { ShoppingCartAction, SaveCartAction })(
  Cart
);
