import { cnt } from "../../constant.action";

const INITIAL_STATE = {
  isSignedIn: false,
  userData: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case cnt.SIGN_IN:
      return {
        ...state,
        isSignedIn: true,
        userData: action.payload,
      };
    case cnt.SIGN_OUT:
      return {
        ...state,
        isSignedIn: false,
        userData: false,
      };
    default:
      return state;
  }
};
