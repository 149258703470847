// import _ from "lodash";
import { cnt } from "../../constant.action";

const INITIAL_CATEGORY_STATE = {
  getCategory: [],
  categoryDetail: {},
};

export const categoryDataRed = (state = INITIAL_CATEGORY_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case cnt.GET_CATEGORY:
      return { ...state, getCategory: payload };

    case cnt.DETAIL_CATEGORY:
      return { ...state, categoryDetail: payload };

    case cnt.CLEAR_CATEGORY_DATA:
      return { ...INITIAL_CATEGORY_STATE };
    default:
      return state;
  }
};
