import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  ShoppingCartAction,
} from "../../services/redux/actions/product-act";
import Sidebar from "../ui-setup/Sidebar";
import Header from "../ui-setup/Header";
import Hero from "../ui-setup/Hero";
import Footer from "../ui-setup/Footer";

import { cnt } from "../../services/constant.action";
import { apis as api } from "../../services/api.action";
import { BOM } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import Notiflix from "notiflix";
import {handleSkeletonProdFac} from "../layouts/Facility"

const ProductByCategory = (props) => {
  /** DEFINED VARIABLES */
  const {
    location,
    history,
    ShoppingCartAction,
    userData
  } = props;

  const { state } = location;
  const { ggParam } = state;
  const { PROFILE_ID:profileId, token } = userData;
  const [category, setCategory] = useState("Category");
  const [productData, setProductData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getDetailById(ggParam)
  }, []);

  const getDetailById = (params) => {
    // BOM.LoadAlert(cnt.LOAD, "Processing");
    // catch company name
    setCategory(params.CA_NAME);
    setIsLoading(true);

    const body = {
      profileId,
      caId: params.CA_ID,
      userToken: token,
    };

    BOM.FetchReqAction(body, api.GetProductByCategory, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        let objList = res.productByCaData;
        
        setProductData(objList)
        setIsLoading(false);
      }
    });
  };

  const onAddToCart = (prObj) => {
    // console.log(prObj);
    if (!prObj) return;

    ShoppingCartAction(
      {
        TRACK_ID: BOM.GenerateTimestamp(),
        CA_NAME: prObj.CA_NAME,
        CO_NAME: prObj.CO_NAME,
        CO_ID: prObj.CO_ID,
        PR_NAME: prObj.PR_NAME,
        PR_PRICE: parseFloat(prObj.PR_PRICE),
        PR_ID: prObj.PR_ID,
        QTY: 1,
        TRACK_STATUS: false,
      },
      "add"
    );
  };

  const renderProductList = productData.map((product) => {
    return (
      <div
        className="col-sm-6 col-lg-4 px-2 px-sm-3 mb-3 mb-sm-5"
        key={product.PR_ID}
      >
        {/* Product */}
        <div className="card border shadow-none text-center h-100">
          <div className="position-relative">
            {/* <img
              className="card-img-top"
              src="../../assets/img/300x180/img4.jpg"
              alt="Image Description"
            /> */}
            <div className="position-absolute top-0 right-0 pt-3 pr-3">
              <button
                type="button"
                className="btn btn-xs btn-icon btn-outline-secondary rounded-circle"
                data-toggle="tooltip"
                data-placement="top"
                title="Save for later"
              >
                <i className="fas fa-heart"></i>
              </button>
            </div>
            <div className="position-absolute top-0 right-0 pt-3 pr-3">
              <button
                type="button"
                className="btn btn-xs btn-icon btn-outline-secondary rounded-circle"
                data-toggle="tooltip"
                data-placement="top"
                title="Save for later"
              >
                <i className="fas fa-heart" />
              </button>
            </div>
          </div>
          <div className="card-body pt-4 px-4 pb-0">
            <div className="mb-2">
              <a
                className="d-inline-block text-body small font-weight-bold mb-1"
                href="#"
              >
                {product.CA_NAME}
              </a>
              <span className="d-block font-size-1">
                <a className="text-inherit" href="single-product.html">
                  {product.PR_NAME.length > 12
                    ? product.PR_NAME.substring(0, 12) + "..."
                    : product.PR_NAME}
                </a>
              </span>
              <div className="d-block">
                <span className="text-dark font-weight-bold">
                {userData.CUR_CODE} {product.PR_PRICE}
                </span>
              </div>
            </div>
          </div>
          <div className="card-footer border-0 pt-0 pb-4 px-4">
            <div className="mb-3">
              <a className="d-inline-flex align-items-center small" href="#">
                <span className="text-warning font-weight-bold">
                  {product.CO_NAME}
                </span>
              </a>
            </div>
            <button
              type="button"
              onClick={() => onAddToCart(product)}
              className="btn btn-sm btn-outline-primary btn-pill transition-3d-hover"
            >
              Add to Cart
            </button>
          </div>
        </div>
        {/* End Product */}
      </div>
    );
  });

  const renderAlertMsg = (message, caName) => {
    return (
      <div
        className="alert alert-soft-danger text-center rounded-0 mb-7"
        role="alert"
      >
        {message} <a className="alert-link">{caName}</a>
      </div>
    );
  };
  return (
    <div>
      {/* ========== HEADER ========== */}
      <Header />
      {/* ========== END HEADER ========== */}

      {/* ========== MAIN CONTENT ========== */}
      <main id="content" role="main">
        {/* Hero Section */}
        <Hero />
        {/* End Hero Section */}
        {/* Apps Section */}
        <div className="container space-bottom-2 space-bottom-lg-3">
          <div className="row">
            <Sidebar />
            <div className="col-lg-9 column-divider-lg">
              <div className="ml-lg-2">
                {/* Title */}
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h3 className="mb-0">{category}</h3>
                  <Link to="/category" className="font-size-1 font-weight-bold">
                    <i className="fas fa-arrow-circle-left fa-sm ml-1" /> {appLabel.back}
                  </Link>
                </div>
                {/* End Title */}
                <div className="col-sm-6 col-md-4 px-2 mb-3">
                    <div className="card card-frame h-100">
                      <Link to="/cart" className="card-body">
                        <div className="media">
                          <div className="avatar avatar-xs mt-1 mr-3">
                            <i className="fa fa-cart-plus text-success specificIcon" />
                          </div>
                          <div className="media-body">
                            <div className="d-flex align-items-center">
                              <span className="d-block text-dark font-weight-bold">
                              {appLabel.myCart}
                              </span>
                            </div>
                            <small className="d-block text-body">&nbsp;</small>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>

                 {/* Alert */}
                 {productData.length === 0 && !isLoading &&renderAlertMsg(appLabel.noDataForMsg, category) }
                {/* End Alert */}

                {/* Products */}
                <div className="row mx-n2 mx-sm-n3 mb-3">
                {isLoading ? handleSkeletonProdFac() : renderProductList }
                </div>
                {/* End Products */}
              </div>
            </div>
          </div>
        </div>
        {/* End Apps Section */}
      </main>
      {/* ========== END MAIN CONTENT ========== */}
      {/* ========== FOOTER ========== */}
      <Footer />
      {/* ========== END FOOTER ========== */}
    </div>
  );
};

const mapStateProps = (state) => {
  // console.log(state.getProductByCa[0].CA_NAME);
  return {
    auth: state.auth,
    userData: state.auth.userData,
    productData: state.productData.getProductByCa,
    categoryData: state.categoryData.getCategory,
  };
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
});
const mapDispatchToProps = { ShoppingCartAction,};

export default connect(mapStateToProps, mapDispatchToProps)(ProductByCategory);