import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../ui-setup/Footer";
import SavedCart from "./SavedCart";


const EmptyCart = () => {
  const [showBlock, setShowBlock] = useState(false);
  return (
    <div>
      {/* ========== MAIN CONTENT ========== */}
      <main id="content" role="main">
        {/* Cart Section */}
        <div className="container space-2 space-lg-3">
          <div className="w-md-80 w-lg-50 text-center mx-md-auto">
            <figure className="max-w-10rem max-w-sm-100rem mx-auto mb-3">
              <img
                className="img-fluid"
                src="../../assets/svg/illustrations/empty-cart.svg"
                alt="SVG"
              />
            </figure>
            <div className="mb-5">
              <div className="col px-1 my-1">
                <h1 className="h4 text-danger">Your cart is currently empty</h1>
                <Link
                  to="/product"
                  className="btn btn-secondary  btn-xs btn-pill transition-3d-hover px-5"
                >
                 <span className="h6 text-white"> Start new cart</span>
                </Link>
              </div>
            </div>
             {/* SAVED CART */}
             <SavedCart />
                {/* END SAVED CART */}
          </div>
        </div>
      </main>
    </div>
  );
};

export default EmptyCart;
