import React from "react";

const Footer = () => {
  return (
    <div>
      <footer
        style={{ marginTop: "15px", background: "#0d47a1" }}
        className="position-relative right-0 bottom-0 left-0"
      >
        <div className="container">
          <div className="d-flex justify-content-between align-items-center space-1">
            {/* Copyright */}
            <p className="small text-white mb-0">©2022-nHouz</p>
            {/* <p className="small text-white mb-0">contact us ( <b>support@nacomoda.com</b> )</p> */}
            <a className="small text-white mb-0" href="https://nacomoda.com">
              From <b>nAcomoda</b>  <i className="fas fa-home"></i> <br/>
              <b>support@nacomoda.com</b>
            </a>
            {/* End Copyright */}
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
