import React, { useState, useRef } from "react";
import { BOM } from "../../services/defined.action";
import { cnt } from "../../services/constant.action";
import { connect } from "react-redux";

const Hero = (props) => {
  const { auth, userData } = props;
  const [userInviteLink, setUserInviteLink] = useState(
    BOM.GetWindowData("origin", "/sign-up/" + userData.REFERENCE_CODE)
  );
  const textInputRef = useRef(null);

  function inviteLinkLogic(e) {
    /** COPY THE TEXT INSIDE THE TEXT FIELD */
    textInputRef.current.select();
    document.execCommand("copy");
    e.target.focus();
    BOM.AlertMsg(
      cnt.COPIED,
      "Link copied. You can now share it with your friends!"
    );
  }

  return (
    <div>
      <div className="container space-top-1 space-top-md-2 space-bottom-2">
        <div className="row align-items-lg-center">
          <div className="col-lg-5 mb-7 mb-lg-0">
            <div className="mb-4">
              <h6> Copy the link below to Invite friends</h6>
              <p></p>
            </div>
            {/* Search Form */}
            <form className=" d-flex align-items-center">
              <div className="d-inline-block w-75 mr-2">
                <input
                  type="text"
                  className="form-control"
                  value={userInviteLink}
                  ref={textInputRef}
                  onChange={(e) => setUserInviteLink(e.target.value)}
                  readOnly
                />
              </div>
              <button
                type="button"
                className="btn btn-primary"
                onClick={inviteLinkLogic}
              >
                <span className="fas fa-copy" />
              </button>
            </form>
            {/* End Search Form */}
          </div>
          <div className="col-lg-7">
            <img
              className="img-fluid"
              src="../../assets/svg/illustrations/we-are-in-office-1.svg"
              alt="Image Descriptio"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateProps = (state) => {
  return {
    auth: state.auth,
    userData: state.auth.userData,
  };
};
export default connect(mapStateProps, {})(Hero);
