import React from 'react'

const Landing =() =>{
  return (
    <div>
          
      {/* ========== MAIN CONTENT ========== */}
      <main id="content" role="main">
        {/* Hero Section */}
        <div className="container space-2">
          <div className="row justify-content-lg-between align-items-lg-center">
            <div className="col-sm-10 col-lg-5 mb-7 mb-lg-0">
              <img
                className="img-fluid"
                src="../../assets/svg/illustrations/reading.svg"
                alt="Image Description"
              />
            </div>
            <div className="col-lg-6">
              <div className="mb-5">
                <h1 className="display-4 mb-3">
                  Unlock your
                  <br />
                  <span className="text-primary font-weight-bold">
                    <span className="js-text-animation" />
                  </span>
                </h1>
                <p className="lead">
                  With our platform, you can quantify your skills, grow in your
                  role and stay relevant on critical topics.
                </p>
              </div>
              <div className="d-sm-flex align-items-sm-center flex-sm-wrap">
                <a className="btn btn-primary mb-2" href="../pages/login.html">
                  Start a Free Trial
                </a>
                <div className="mx-2" />
                {/* Fancybox */}
                <a
                  className="js-fancybox video-player video-player-btn media align-items-center text-dark mb-2"
                  href="#"
                  data-hs-fancybox-options='{
           "src": "//youtube.com/0qisGSwZym4",
           "caption": "Front - Responsive Website Template",
           "speed": 700,
           "buttons": ["fullScreen", "close"],
           "youtube": {
             "autoplay": 1
           }
         }'
                >
                  <span className="video-player-icon shadow-soft mr-3">
                    <i className="fa fa-play" />
                  </span>
                  <span className="media-body">How it works</span>
                </a>
                {/* End Fancybox */}
              </div>
            </div>
          </div>
        </div>
        {/* End Hero Section */}
        {/* Popular Categories Section */}
        <div
          className="space-bottom-2 space-bottom-lg-3"
          style={{
            background:
              "url(../../assets/svg/components/abstract-shapes-9.svg) center no-repeat",
          }}
        >
          <div className="position-relative">
            <div className="container space-2">
              {/* Title */}
              <div className="row align-items-md-center mb-7">
                <div className="col-md-6 mb-4 mb-md-0">
                  <h2>Check out our newest and most popular programs</h2>
                </div>
                <div className="col-md-6 text-md-right">
                  <a className="font-weight-bold" href="#">
                    See all Programs{" "}
                    <i className="fa fa-angle-right fa-sm ml-1" />
                  </a>
                </div>
              </div>
              {/* End Title */}
              <div
                className="js-slick-carousel slick slick-equal-height slick-gutters-3 slick-center-mode-right slick-center-mode-right-offset"
                data-hs-slick-carousel-options='{
           "prevArrow": "<span class=\"fa fa-arrow-left slick-arrow slick-arrow-primary-white slick-arrow-left slick-arrow-centered-y shadow-soft rounded-circle ml-sm-n2\"></span>",
           "nextArrow": "<span class=\"fa fa-arrow-right slick-arrow slick-arrow-primary-white slick-arrow-right slick-arrow-centered-y shadow-soft rounded-circle mr-sm-2 mr-xl-4\"></span>",
           "slidesToShow": 5,
           "infinite": true,
           "responsive": [{
             "breakpoint": 1200,
               "settings": {
                 "slidesToShow": 4
               }
             }, {
             "breakpoint": 992,
               "settings": {
                 "slidesToShow": 3
               }
             }, {
             "breakpoint": 768,
             "settings": {
               "slidesToShow": 2
             }
             }, {
             "breakpoint": 554,
             "settings": {
               "slidesToShow": 1
             }
           }]
         }'
              >
                {/* Article */}
                <article className="js-slide pt-2">
                  <a
                    className="card bg-img-hero w-100 min-h-270rem transition-3d-hover"
                    href="course-description.html"
                    style={{
                      backgroundImage:
                        "url(../../assets/img/400x500/img14.jpg)",
                    }}
                  >
                    <div className="card-body">
                      <span className="d-block small text-white-70 font-weight-bold text-cap mb-2">
                        New
                      </span>
                      <h3 className="text-white">Cloud computing</h3>
                    </div>
                    <div className="card-footer border-0 bg-transparent pt-0">
                      <span className="text-white font-size-1 font-weight-bold">
                        Read now
                      </span>
                    </div>
                  </a>
                </article>
                {/* End Article */}
                {/* Article */}
                <article className="js-slide pt-2">
                  <a
                    className="card bg-img-hero w-100 min-h-270rem transition-3d-hover"
                    href="course-description.html"
                    style={{
                      backgroundImage:
                        "url(../../assets/img/400x500/img13.jpg)",
                    }}
                  >
                    <div className="card-body">
                      <span className="d-block small text-white-70 font-weight-bold text-cap mb-2">
                        Phython
                      </span>
                      <h3 className="text-white">
                        What's new in Phython 3.7.2
                      </h3>
                    </div>
                    <div className="card-footer border-0 bg-transparent pt-0">
                      <span className="text-white font-size-1 font-weight-bold">
                        Read now
                      </span>
                    </div>
                  </a>
                </article>
                {/* End Article */}
                {/* Article */}
                <article className="js-slide pt-2">
                  <a
                    className="card bg-img-hero w-100 min-h-270rem transition-3d-hover"
                    href="course-description.html"
                    style={{
                      backgroundImage:
                        "url(../../assets/img/400x500/img15.jpg)",
                    }}
                  >
                    <div className="card-body">
                      <span className="d-block small text-white-70 font-weight-bold text-cap mb-2">
                        Tooling
                      </span>
                      <h3 className="text-white">Build a staging server</h3>
                    </div>
                    <div className="card-footer border-0 bg-transparent pt-0">
                      <span className="text-white font-size-1 font-weight-bold">
                        Read now
                      </span>
                    </div>
                  </a>
                </article>
                {/* End Article */}
                {/* Article */}
                <article className="js-slide pt-2">
                  <a
                    className="card bg-img-hero w-100 min-h-270rem transition-3d-hover"
                    href="course-description.html"
                    style={{
                      backgroundImage:
                        "url(../../assets/img/400x500/img16.jpg)",
                    }}
                  >
                    <div className="card-body">
                      <span className="d-block small text-white-70 font-weight-bold text-cap mb-2">
                        JavaScript
                      </span>
                      <h3 className="text-white">Laravel, Vue and SPAs</h3>
                    </div>
                    <div className="card-footer border-0 bg-transparent pt-0">
                      <span className="text-white font-size-1 font-weight-bold">
                        Read now
                      </span>
                    </div>
                  </a>
                </article>
                {/* End Article */}
                {/* Article */}
                <article className="js-slide pt-2">
                  <a
                    className="card bg-img-hero w-100 min-h-270rem transition-3d-hover"
                    href="course-description.html"
                    style={{
                      backgroundImage:
                        "url(../../assets/img/400x500/img17.jpg)",
                    }}
                  >
                    <div className="card-body">
                      <span className="d-block small text-white-70 font-weight-bold text-cap mb-2">
                        Popular
                      </span>
                      <h3 className="text-white">Artificial Intelligence</h3>
                    </div>
                    <div className="card-footer border-0 bg-transparent pt-0">
                      <span className="text-white font-size-1 font-weight-bold">
                        Read now
                      </span>
                    </div>
                  </a>
                </article>
                {/* End Article */}
                {/* Article */}
                <article className="js-slide pt-2">
                  <a
                    className="card bg-img-hero w-100 min-h-270rem transition-3d-hover"
                    href="course-description.html"
                    style={{
                      backgroundImage:
                        "url(../../assets/img/400x500/img18.jpg)",
                    }}
                  >
                    <div className="card-body">
                      <span className="d-block small text-white-70 font-weight-bold text-cap mb-2">
                        PHP
                      </span>
                      <h3 className="text-white">
                        Programming terms explained
                      </h3>
                    </div>
                    <div className="card-footer border-0 bg-transparent pt-0">
                      <span className="text-white font-size-1 font-weight-bold">
                        Read now
                      </span>
                    </div>
                  </a>
                </article>
                {/* End Article */}
              </div>
            </div>
            <div className="w-100 w-md-65 bg-light position-absolute top-0 right-0 bottom-0 rounded-left z-index-n1" />
          </div>
        </div>
        {/* End Popular Categories Section */}
        {/* Featured Topics Section */}
        <div className="container space-sm-2 space-bottom-lg-3">
          {/* Title */}
          <div className="w-md-80 text-center mx-md-auto mb-9">
            <h2>Featured courses</h2>
            <p>Discover your perfect program in our courses.</p>
          </div>
          {/* End Title */}
          {/* Featured Topics Carousel */}
          <div className="row mb-5">
            <article className="col-md-6 col-lg-4 mb-5">
              {/* Article */}
              <div className="card border h-100">
                <div className="card-img-top position-relative">
                  <img
                    className="card-img-top"
                    src="../../assets/svg/components/graphics-1.svg"
                    alt="Image Description"
                  />
                  <div className="position-absolute top-0 left-0 mt-3 ml-3">
                    <small className="btn btn-xs btn-success btn-pill text-uppercase shadow-soft mb-3">
                      Bestseller
                    </small>
                  </div>
                  <div className="position-absolute bottom-0 left-0 mb-3 ml-4">
                    <div className="d-flex align-items-center flex-wrap">
                      <ul className="list-inline mt-n1 mb-0 mr-2">
                        <li className="list-inline-item mx-0">
                          <img
                            src="../../assets/svg/illustrations/star.svg"
                            alt="Review rating"
                            width={14}
                          />
                        </li>
                        <li className="list-inline-item mx-0">
                          <img
                            src="../../assets/svg/illustrations/star.svg"
                            alt="Review rating"
                            width={14}
                          />
                        </li>
                        <li className="list-inline-item mx-0">
                          <img
                            src="../../assets/svg/illustrations/star.svg"
                            alt="Review rating"
                            width={14}
                          />
                        </li>
                        <li className="list-inline-item mx-0">
                          <img
                            src="../../assets/svg/illustrations/star.svg"
                            alt="Review rating"
                            width={14}
                          />
                        </li>
                        <li className="list-inline-item mx-0">
                          <img
                            src="../../assets/svg/illustrations/star.svg"
                            alt="Review rating"
                            width={14}
                          />
                        </li>
                      </ul>
                      <span className="d-inline-block">
                        <small className="font-weight-bold text-white mr-1">
                          4.91
                        </small>
                        <small className="text-white-70">(1.5k+ reviews)</small>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <small className="d-block small font-weight-bold text-cap mb-2">
                    Code
                  </small>
                  <div className="mb-3">
                    <h3>
                      <a
                        className="text-inherit"
                        href="course-description.html"
                      >
                        Complete Python Bootcamp: Go from zero to hero in Python
                        3
                      </a>
                    </h3>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="avatar-group">
                      <a
                        className="avatar avatar-xs avatar-circle"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Nataly Gaga"
                        href="#"
                      >
                        <img
                          className="avatar-img"
                          src="../../assets/img/100x100/img1.jpg"
                          alt="Image Description"
                        />
                      </a>
                    </div>
                    <div className="d-flex align-items-center ml-auto">
                      <div className="small text-muted">
                        <i className="fa fa-book-reader d-block d-sm-inline-block mb-1 mb-sm-0 mr-1" />
                        10 lessons
                      </div>
                      <small className="text-muted mx-2">|</small>
                      <div className="small text-muted">
                        <i className="fa fa-clock d-block d-sm-inline-block mb-1 mb-sm-0 mr-1" />
                        3h 25m
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer border-0 pt-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="mr-2">
                      <small className="d-block text-muted text-lh-sm">
                        <del>$114.99</del>
                      </small>
                      <span className="d-block h5 text-lh-sm mb-0">$99.99</span>
                    </div>
                    <a
                      className="btn btn-sm btn-primary transition-3d-hover"
                      href="course-description.html"
                    >
                      Preview
                    </a>
                  </div>
                </div>
              </div>
              {/* End Article */}
            </article>
            <article className="col-md-6 col-lg-4 mb-5">
              {/* Article */}
              <div className="card border h-100">
                <div className="card-img-top position-relative">
                  <img
                    className="card-img-top"
                    src="../../assets/svg/components/graphics-2.svg"
                    alt="Image Description"
                  />
                  <div className="position-absolute bottom-0 left-0 mb-3 ml-4">
                    <div className="d-flex align-items-center flex-wrap">
                      <ul className="list-inline mt-n1 mb-0 mr-2">
                        <li className="list-inline-item mx-0">
                          <img
                            src="../../assets/svg/illustrations/star.svg"
                            alt="Review rating"
                            width={14}
                          />
                        </li>
                        <li className="list-inline-item mx-0">
                          <img
                            src="../../assets/svg/illustrations/star.svg"
                            alt="Review rating"
                            width={14}
                          />
                        </li>
                        <li className="list-inline-item mx-0">
                          <img
                            src="../../assets/svg/illustrations/star.svg"
                            alt="Review rating"
                            width={14}
                          />
                        </li>
                        <li className="list-inline-item mx-0">
                          <img
                            src="../../assets/svg/illustrations/star.svg"
                            alt="Review rating"
                            width={14}
                          />
                        </li>
                        <li className="list-inline-item mx-0">
                          <img
                            src="../../assets/svg/illustrations/star.svg"
                            alt="Review rating"
                            width={14}
                          />
                        </li>
                      </ul>
                      <span className="d-inline-block">
                        <small className="font-weight-bold text-white mr-1">
                          4.95
                        </small>
                        <small className="text-white-70">(1k+ reviews)</small>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <small className="d-block small font-weight-bold text-cap mb-2">
                    Design &amp; Illustration
                  </small>
                  <div className="mb-3">
                    <h3>
                      <a
                        className="text-inherit"
                        href="course-description.html"
                      >
                        From the Top: Adobe Illustrator for Beginners
                      </a>
                    </h3>
                  </div>
                  <div className="d-flex align-items-center mb-4">
                    <div className="avatar-group">
                      <a
                        className="avatar avatar-xs avatar-circle"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Emily Milda"
                        href="#"
                      >
                        <img
                          className="avatar-img"
                          src="../../assets/img/100x100/img2.jpg"
                          alt="Image Description"
                        />
                      </a>
                      <a
                        className="avatar avatar-xs avatar-circle"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="John O'nolan"
                        href="#"
                      >
                        <img
                          className="avatar-img"
                          src="../../assets/img/100x100/img4.jpg"
                          alt="Image Description"
                        />
                      </a>
                    </div>
                    <div className="d-flex align-items-center ml-auto">
                      <div className="small text-muted">
                        <i className="fa fa-book-reader d-block d-sm-inline-block mb-1 mb-sm-0 mr-1" />
                        8 lessons
                      </div>
                      <small className="text-muted mx-2">|</small>
                      <div className="small text-muted">
                        <i className="fa fa-clock d-block d-sm-inline-block mb-1 mb-sm-0 mr-1" />
                        7h 59m
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer border-0 pt-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="mr-2">
                      <small className="d-block text-muted text-lh-sm">
                        <del>$129.99</del>
                      </small>
                      <span className="d-block h5 text-lh-sm mb-0">
                        $119.99
                      </span>
                    </div>
                    <a
                      className="btn btn-sm btn-primary transition-3d-hover"
                      href="course-description.html"
                    >
                      Preview
                    </a>
                  </div>
                </div>
              </div>
              {/* End Article */}
            </article>
            <article className="col-md-6 col-lg-4 mb-5">
              {/* Article */}
              <div className="card border h-100">
                <div className="card-img-top position-relative">
                  <img
                    className="card-img-top"
                    src="../../assets/svg/components/graphics-4.svg"
                    alt="Image Description"
                  />
                  <div className="position-absolute top-0 left-0 mt-3 ml-3">
                    <small className="btn btn-xs btn-success btn-pill text-uppercase shadow-soft mb-3">
                      Featured
                    </small>
                  </div>
                  <div className="position-absolute bottom-0 left-0 mb-3 ml-4">
                    <div className="d-flex align-items-center flex-wrap">
                      <ul className="list-inline mt-n1 mb-0 mr-2">
                        <li className="list-inline-item mx-0">
                          <img
                            src="../../assets/svg/illustrations/star.svg"
                            alt="Review rating"
                            width={14}
                          />
                        </li>
                        <li className="list-inline-item mx-0">
                          <img
                            src="../../assets/svg/illustrations/star.svg"
                            alt="Review rating"
                            width={14}
                          />
                        </li>
                        <li className="list-inline-item mx-0">
                          <img
                            src="../../assets/svg/illustrations/star.svg"
                            alt="Review rating"
                            width={14}
                          />
                        </li>
                        <li className="list-inline-item mx-0">
                          <img
                            src="../../assets/svg/illustrations/star.svg"
                            alt="Review rating"
                            width={14}
                          />
                        </li>
                        <li className="list-inline-item mx-0">
                          <img
                            src="../../assets/svg/illustrations/star-muted.svg"
                            alt="Review rating"
                            width={14}
                          />
                        </li>
                      </ul>
                      <span className="d-inline-block">
                        <small className="font-weight-bold text-white mr-1">
                          4.73
                        </small>
                        <small className="text-white-70">(4.7k+ reviews)</small>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <small className="d-block small font-weight-bold text-cap mb-2">
                    Code
                  </small>
                  <div className="mb-3">
                    <h3>
                      <a
                        className="text-inherit"
                        href="course-description.html"
                      >
                        Get started with Vue.js
                      </a>
                    </h3>
                  </div>
                  <div className="d-flex align-items-center mb-4">
                    <div className="avatar-group">
                      <a
                        className="avatar avatar-xs avatar-circle"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Aaron Larsson"
                        href="#"
                      >
                        <img
                          className="avatar-img"
                          src="../../assets/img/100x100/img3.jpg"
                          alt="Image Description"
                        />
                      </a>
                      <a
                        className="avatar avatar-xs avatar-circle"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Hanna Wolfe"
                        href="#"
                      >
                        <img
                          className="avatar-img"
                          src="../../assets/img/100x100/img11.jpg"
                          alt="Image Description"
                        />
                      </a>
                    </div>
                    <div className="d-flex align-items-center ml-auto">
                      <div className="small text-muted">
                        <i className="fa fa-book-reader d-block d-sm-inline-block mb-1 mb-sm-0 mr-1" />
                        25 lessons
                      </div>
                      <small className="text-muted mx-2">|</small>
                      <div className="small text-muted">
                        <i className="fa fa-clock d-block d-sm-inline-block mb-1 mb-sm-0 mr-1" />
                        17h 46m
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer border-0 pt-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="mr-2">
                      <small className="d-block text-muted text-lh-sm">
                        <del>$169.99</del>
                      </small>
                      <span className="d-block h5 text-lh-sm mb-0">
                        $129.99
                      </span>
                    </div>
                    <a
                      className="btn btn-sm btn-primary transition-3d-hover"
                      href="course-description.html"
                    >
                      Preview
                    </a>
                  </div>
                </div>
              </div>
              {/* End Article */}
            </article>
            <article className="col-md-6 col-lg-4 mb-5">
              {/* Article */}
              <div className="card border h-100">
                <div className="card-img-top position-relative">
                  <img
                    className="card-img-top"
                    src="../../assets/svg/components/graphics-5.svg"
                    alt="Image Description"
                  />
                  <div className="position-absolute bottom-0 left-0 mb-3 ml-4">
                    <div className="d-flex align-items-center flex-wrap">
                      <ul className="list-inline mt-n1 mb-0 mr-2">
                        <li className="list-inline-item mx-0">
                          <img
                            src="../../assets/svg/illustrations/star.svg"
                            alt="Review rating"
                            width={14}
                          />
                        </li>
                        <li className="list-inline-item mx-0">
                          <img
                            src="../../assets/svg/illustrations/star.svg"
                            alt="Review rating"
                            width={14}
                          />
                        </li>
                        <li className="list-inline-item mx-0">
                          <img
                            src="../../assets/svg/illustrations/star.svg"
                            alt="Review rating"
                            width={14}
                          />
                        </li>
                        <li className="list-inline-item mx-0">
                          <img
                            src="../../assets/svg/illustrations/star.svg"
                            alt="Review rating"
                            width={14}
                          />
                        </li>
                        <li className="list-inline-item mx-0">
                          <img
                            src="../../assets/svg/illustrations/star-half.svg"
                            alt="Review rating"
                            width={14}
                          />
                        </li>
                      </ul>
                      <span className="d-inline-block">
                        <small className="font-weight-bold text-white mr-1">
                          4.87
                        </small>
                        <small className="text-white-70">(3.8k+ reviews)</small>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <small className="d-block small font-weight-bold text-cap mb-2">
                    Code
                  </small>
                  <div className="mb-3">
                    <h3>
                      <a
                        className="text-inherit"
                        href="course-description.html"
                      >
                        The Ultimate MySQL Bootcamp: Go from SQL Beginner to
                        Expert
                      </a>
                    </h3>
                  </div>
                  <div className="d-flex align-items-center mb-4">
                    <div className="avatar-group">
                      <a
                        className="avatar avatar-xs avatar-circle"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Hanna Wolfe"
                        href="#"
                      >
                        <img
                          className="avatar-img"
                          src="../../assets/img/100x100/img11.jpg"
                          alt="Image Description"
                        />
                      </a>
                    </div>
                    <div className="d-flex align-items-center ml-auto">
                      <div className="small text-muted">
                        <i className="fa fa-book-reader d-block d-sm-inline-block mb-1 mb-sm-0 mr-1" />
                        42 lessons
                      </div>
                      <small className="text-muted mx-2">|</small>
                      <div className="small text-muted">
                        <i className="fa fa-clock d-block d-sm-inline-block mb-1 mb-sm-0 mr-1" />
                        31h 5m
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer border-0 pt-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="mr-2">
                      <small className="d-block text-muted text-lh-sm">
                        <del>$159.99</del>
                      </small>
                      <span className="d-block h5 text-lh-sm mb-0">
                        $111.99
                      </span>
                    </div>
                    <a
                      className="btn btn-sm btn-primary transition-3d-hover"
                      href="course-description.html"
                    >
                      Preview
                    </a>
                  </div>
                </div>
              </div>
              {/* End Article */}
            </article>
            <article className="col-md-6 col-lg-4 mb-5">
              {/* Article */}
              <div className="card border h-100">
                <div className="card-img-top position-relative">
                  <img
                    className="card-img-top"
                    src="../../assets/svg/components/graphics-6.svg"
                    alt="Image Description"
                  />
                  <div className="position-absolute bottom-0 left-0 mb-3 ml-4">
                    <div className="d-flex align-items-center flex-wrap">
                      <ul className="list-inline mt-n1 mb-0 mr-2">
                        <li className="list-inline-item mx-0">
                          <img
                            src="../../assets/svg/illustrations/star.svg"
                            alt="Review rating"
                            width={14}
                          />
                        </li>
                        <li className="list-inline-item mx-0">
                          <img
                            src="../../assets/svg/illustrations/star.svg"
                            alt="Review rating"
                            width={14}
                          />
                        </li>
                        <li className="list-inline-item mx-0">
                          <img
                            src="../../assets/svg/illustrations/star.svg"
                            alt="Review rating"
                            width={14}
                          />
                        </li>
                        <li className="list-inline-item mx-0">
                          <img
                            src="../../assets/svg/illustrations/star.svg"
                            alt="Review rating"
                            width={14}
                          />
                        </li>
                        <li className="list-inline-item mx-0">
                          <img
                            src="../../assets/svg/illustrations/star-half.svg"
                            alt="Review rating"
                            width={14}
                          />
                        </li>
                      </ul>
                      <span className="d-inline-block">
                        <small className="font-weight-bold text-white mr-1">
                          4.84
                        </small>
                        <small className="text-white-70">(723 reviews)</small>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <small className="d-block small font-weight-bold text-cap mb-2">
                    Code
                  </small>
                  <div className="mb-3">
                    <h3>
                      <a
                        className="text-inherit"
                        href="course-description.html"
                      >
                        Coding block for WordPress
                      </a>
                    </h3>
                  </div>
                  <div className="d-flex align-items-center mb-4">
                    <div className="avatar-group">
                      <a
                        className="avatar avatar-xs avatar-circle"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Hanna Wolfe"
                        href="#"
                      >
                        <img
                          className="avatar-img"
                          src="../../assets/img/100x100/img11.jpg"
                          alt="Image Description"
                        />
                      </a>
                      <div
                        className="avatar avatar-xs avatar-circle"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="John O'nolan"
                      >
                        <img
                          className="avatar-img"
                          src="../../assets/img/100x100/img4.jpg"
                          alt="Image Description"
                        />
                      </div>
                    </div>
                    <div className="d-flex align-items-center ml-auto">
                      <div className="small text-muted">
                        <i className="fa fa-book-reader d-block d-sm-inline-block mb-1 mb-sm-0 mr-1" />
                        5 lessons
                      </div>
                      <small className="text-muted mx-2">|</small>
                      <div className="small text-muted">
                        <i className="fa fa-clock d-block d-sm-inline-block mb-1 mb-sm-0 mr-1" />
                        8h 12m
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer border-0 pt-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="mr-2">
                      <small className="d-block text-muted text-lh-sm">
                        <del>$64.99</del>
                      </small>
                      <span className="d-block h5 text-lh-sm mb-0">$29.99</span>
                    </div>
                    <a
                      className="btn btn-sm btn-primary transition-3d-hover"
                      href="course-description.html"
                    >
                      Preview
                    </a>
                  </div>
                </div>
              </div>
              {/* End Article */}
            </article>
            <article className="col-md-6 col-lg-4 mb-5">
              {/* Article */}
              <div className="card border h-100">
                <div className="card-img-top position-relative">
                  <img
                    className="card-img-top"
                    src="../../assets/svg/components/graphics-3.svg"
                    alt="Image Description"
                  />
                  <div className="position-absolute top-0 left-0 mt-3 ml-3">
                    <small className="btn btn-xs btn-success btn-pill text-uppercase shadow-soft mb-3">
                      Hot
                    </small>
                  </div>
                  <div className="position-absolute bottom-0 left-0 mb-3 ml-4">
                    <div className="d-flex align-items-center flex-wrap">
                      <ul className="list-inline mt-n1 mb-0 mr-2">
                        <li className="list-inline-item mx-0">
                          <img
                            src="../../assets/svg/illustrations/star.svg"
                            alt="Review rating"
                            width={14}
                          />
                        </li>
                        <li className="list-inline-item mx-0">
                          <img
                            src="../../assets/svg/illustrations/star.svg"
                            alt="Review rating"
                            width={14}
                          />
                        </li>
                        <li className="list-inline-item mx-0">
                          <img
                            src="../../assets/svg/illustrations/star.svg"
                            alt="Review rating"
                            width={14}
                          />
                        </li>
                        <li className="list-inline-item mx-0">
                          <img
                            src="../../assets/svg/illustrations/star.svg"
                            alt="Review rating"
                            width={14}
                          />
                        </li>
                        <li className="list-inline-item mx-0">
                          <img
                            src="../../assets/svg/illustrations/star.svg"
                            alt="Review rating"
                            width={14}
                          />
                        </li>
                      </ul>
                      <span className="d-inline-block">
                        <small className="font-weight-bold text-white mr-1">
                          4.9
                        </small>
                        <small className="text-white-70">(961 reviews)</small>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <small className="d-block small font-weight-bold text-cap mb-2">
                    Design &amp; Illustration
                  </small>
                  <div className="mb-3">
                    <h3>
                      <a
                        className="text-inherit"
                        href="course-description.html"
                      >
                        Creative Magazine Layout Design
                      </a>
                    </h3>
                  </div>
                  <div className="d-flex align-items-center mb-4">
                    <div className="avatar-group">
                      <a
                        className="avatar avatar-xs avatar-circle"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Hanna Wolfe"
                        href="#"
                      >
                        <img
                          className="avatar-img"
                          src="../../assets/img/100x100/img11.jpg"
                          alt="Image Description"
                        />
                      </a>
                    </div>
                    <div className="d-flex align-items-center ml-auto">
                      <div className="small text-muted">
                        <i className="fa fa-book-reader d-block d-sm-inline-block mb-1 mb-sm-0 mr-1" />
                        35 lessons
                      </div>
                      <small className="text-muted mx-2">|</small>
                      <div className="small text-muted">
                        <i className="fa fa-clock d-block d-sm-inline-block mb-1 mb-sm-0 mr-1" />
                        21h
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer border-0 pt-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="mr-2">
                      <small className="d-block text-muted text-lh-sm">
                        <del>$179.99</del>
                      </small>
                      <span className="d-block h5 text-lh-sm mb-0">
                        $129.99
                      </span>
                    </div>
                    <a
                      className="btn btn-sm btn-primary transition-3d-hover"
                      href="course-description.html"
                    >
                      Preview
                    </a>
                  </div>
                </div>
              </div>
              {/* End Article */}
            </article>
          </div>
          {/* End Featured Topics Carousel */}
          <div className="text-center">
            <a className="font-weight-bold" href="courses-listing.html">
              See all Courses <i className="fa fa-angle-right fa-sm ml-1" />
            </a>
          </div>
        </div>
        {/* End Featured Topics Section */}
        {/* Testimonials Section */}
        <div className="overflow-hidden space-2">
          <div className="position-relative bg-light text-center rounded z-index-2 mx-3 mx-md-11">
            <div className="container space-2">
              {/* Title */}
              <div className="w-md-80 w-lg-50 text-center mx-md-auto mb-6">
                <div className="mb-3">
                  <div className="position-relative max-w-13rem mx-auto mb-2">
                    <img
                      className="img-fluid"
                      src="../../assets/svg/illustrations/review-rating-shield.svg"
                      alt="Image Description"
                    />
                    <span className="position-absolute top-0 right-0 left-0 z-index-2 text-white font-size-2 font-weight-bold mt-2">
                      4.8
                    </span>
                  </div>
                  <span>37,500+ reviews</span>
                </div>
              </div>
              {/* End Title */}
              {/* Blockquote */}
              <div className="w-md-80 mx-md-auto mb-6">
                <blockquote className="font-size-2 text-dark">
                  "The best part about Front Course is the selection. You can
                  find a course for anything you want to learn! Thank you Front
                  Course! You've renewed my passion for learning and my dream of
                  becoming a web developer. I really enjoyed and now I work as a
                  front-end developer."
                </blockquote>
              </div>
              {/* End Blockquote */}
              {/* Reviewer */}
              <div className="w-lg-50 mx-lg-auto">
                <div className="avatar avatar-circle mb-3">
                  <img
                    className="avatar-img"
                    src="../../assets/img/100x100/img1.jpg"
                    alt="Image Description"
                  />
                </div>
                <h5 className="mb-0">Christina Kray</h5>
                <small>Satisfied user</small>
              </div>
              {/* End Reviewer */}
            </div>
            {/* SVG Shapes */}
            <figure className="position-absolute top-0 left-0 mt-10 ml-10">
              <img
                src="../../assets/svg/components/abstract-shapes-11.svg"
                alt="SVG"
              />
            </figure>
            <figure className="max-w-15rem w-100 position-absolute bottom-0 right-0">
              <div className="mb-n7 mr-n7">
                <img
                  className="img-fluid"
                  src="../../assets/svg/components/dots-1.svg"
                  alt="Image Description"
                />
              </div>
            </figure>
            {/* End SVG Shapes */}
          </div>
        </div>
        {/* End Testimonials Section */}
        {/* Clients Section */}
        <div className="container space-top-1 space-top-lg-0">
          <div className="w-lg-80 mx-lg-auto">
            <div className="row justify-content-center">
              <div className="col-4 col-sm-3 col-md mb-4">
                <img
                  className="max-w-11rem max-w-md-13rem mx-auto"
                  src="../../assets/svg/clients-logo/airbnb.svg"
                  alt="Image Description"
                />
              </div>
              <div className="col-4 col-sm-3 col-md mb-4">
                <img
                  className="max-w-11rem max-w-md-13rem mx-auto"
                  src="../../assets/svg/clients-logo/slack.svg"
                  alt="Image Description"
                />
              </div>
              <div className="col-4 col-sm-3 col-md mb-4">
                <img
                  className="max-w-11rem max-w-md-13rem mx-auto"
                  src="../../assets/svg/clients-logo/fitbit.svg"
                  alt="Image Description"
                />
              </div>
              <div className="col-4 col-sm-3 col-md mb-4">
                <img
                  className="max-w-11rem max-w-md-13rem mx-auto"
                  src="../../assets/svg/clients-logo/hubspot.svg"
                  alt="Image Description"
                />
              </div>
              <div className="col-4 col-sm-3 col-md mb-4">
                <img
                  className="max-w-11rem max-w-md-13rem mx-auto"
                  src="../../assets/svg/clients-logo/weebly.svg"
                  alt="Image Description"
                />
              </div>
            </div>
          </div>
        </div>
        {/* End Clients Section */}
        {/* Signup Form Section */}
        <div className="position-relative">
          <div className="container space-2 space-lg-3">
            <div className="row justify-content-lg-between align-items-lg-center">
              <div className="col-lg-5 mb-7 mb-lg-0">
                {/* Info */}
                <div className="mb-5">
                  <h2>
                    Thousands of experts around the world ready to help you.
                  </h2>
                  <p>
                    See why leading organizations choose Front Course for
                    Business as their destination for employee learning.
                  </p>
                </div>
                <h4>Learn more about:</h4>
                <div className="media text-body mb-3">
                  <i className="fas fa-check-circle text-success mt-1 mr-2" />
                  <div className="media-body">
                    Unlimited access to the top 3,500+ courses
                  </div>
                </div>
                <div className="media text-body mb-3">
                  <i className="fas fa-check-circle text-success mt-1 mr-2" />
                  <div className="media-body">
                    Fresh content taught by 1,300+ experts – for any learning
                    style
                  </div>
                </div>
                <div className="media text-body mb-3">
                  <i className="fas fa-check-circle text-success mt-1 mr-2" />
                  <div className="media-body">
                    Actionable learning insights{" "}
                    <span className="badge badge-warning badge-pill ml-1">
                      Beta
                    </span>
                  </div>
                </div>
                {/* End Info */}
              </div>
              <div className="col-lg-6">
                {/* Signup Form */}
                <form className="js-validate card border w-md-85 w-lg-100 mx-md-auto">
                  <div className="card-header bg-primary text-white text-center py-4 px-5 px-md-6">
                    <h4 className="text-white mb-0">
                      Try it free for 7 days starting at $59
                    </h4>
                  </div>
                  <div className="card-body p-md-6">
                    <div className="row">
                      <div className="col-sm-6 mb-3">
                        {/* Form Group */}
                        <div className="js-form-message form-group">
                          <label htmlFor="firstName" className="input-label">
                            First name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="firstName"
                            id="firstName"
                            placeholder="Nataly"
                            aria-label="Nataly"
                            required
                            data-msg="Please enter first your name"
                          />
                        </div>
                        {/* End Form Group */}
                      </div>
                      <div className="col-sm-6 mb-3">
                        {/* Form Group */}
                        <div className="js-form-message form-group">
                          <label htmlFor="lastName" className="input-label">
                            Last name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="lastName"
                            id="lastName"
                            placeholder="Gaga"
                            aria-label="Gaga"
                            required
                            data-msg="Please enter last your name"
                          />
                        </div>
                        {/* End Form Group */}
                      </div>
                      <div className="col-sm-12 mb-3">
                        {/* Form Group */}
                        <div className="js-form-message form-group">
                          <label htmlFor="emailAddress" className="input-label">
                            Email address
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            name="emailAddress"
                            id="emailAddress"
                            placeholder="nayagaga@pixeel.com"
                            aria-label="alex@pixeel.com"
                            required
                            data-msg="Please enter a valid email address"
                          />
                        </div>
                        {/* End Form Group */}
                      </div>
                      <div className="col-sm-6 mb-3">
                        {/* Form Group */}
                        <div className="js-form-message form-group">
                          <label htmlFor="password" className="input-label">
                            Password
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="passowrd"
                            id="password"
                            placeholder="*********"
                            aria-label="*********"
                            required
                            data-msg="Your password is invalid. Please try again"
                          />
                        </div>
                        {/* End Form Group */}
                      </div>
                      <div className="col-sm-6 mb-3">
                        {/* Form Group */}
                        <div className="js-form-message form-group">
                          <label
                            htmlFor="confirmPassword"
                            className="input-label"
                          >
                            Confirm password
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="confirmPassword"
                            id="confirmPassword"
                            placeholder="*********"
                            aria-label="*********"
                            required
                            data-msg="Password does not match the confirm password"
                          />
                        </div>
                        {/* End Form Group */}
                      </div>
                    </div>
                    {/* Checkbox */}
                    <div className="js-form-message mb-5">
                      <div className="custom-control custom-checkbox d-flex align-items-center text-muted">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="termsCheckbox"
                          name="termsCheckbox"
                          required
                          data-msg="Please accept our Terms and Conditions."
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="termsCheckbox"
                        >
                          <small>
                            I agree to the
                            <a
                              className="link-underline"
                              href="../pages/terms.html"
                            >
                              Terms and Conditions
                            </a>
                          </small>
                        </label>
                      </div>
                    </div>
                    {/* End Checkbox */}
                    <div className="row align-items-center">
                      <div className="col-sm-7 mb-3 mb-sm-0">
                        <p className="font-size-1 text-muted mb-0">
                          Already have an account?{" "}
                          <a className="font-weight-bold" href="#">
                            Log In
                          </a>
                        </p>
                      </div>
                      <div className="col-sm-5 text-sm-right">
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary"
                        >
                          Sign Up Now
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                {/* End Signup Form */}
              </div>
            </div>
          </div>
        </div>
        {/* End Signup Form Section */}
        {/* CTA Section */}
        <div className="container space-bottom-2">
          <div
            className="text-center py-6"
            style={{
              background:
                "url(../../assets/svg/components/abstract-shapes-19.svg) center no-repeat",
            }}
          >
            <h2>Find the right learning path for you</h2>
            <p>Answer a few questions and match your goals to our programs.</p>
            <span className="d-block mt-5">
              <a className="btn btn-primary transition-3d-hover" href="#">
                Explore by Category
              </a>
            </span>
          </div>
        </div>
        {/* End CTA Section */}
      </main>
      {/* ========== END MAIN CONTENT ========== */}
    </div>
  )
}

export default Landing
