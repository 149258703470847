// /**
//  * @author Gedeon NdundeCode
//  * @date 06/12/2021
//  */
import Auth, { headerOptions as options, apis as api } from "../../api.action";
import { BOM } from "../../defined.action";
import history from "../../../history";
import { cnt } from "../../constant.action";
import Notiflix from "notiflix";

// export const GetProductAction = (body) => {
//   // LoadAlert(cnt.LOAD, "Processing");
//   try {
//     return async (dispatch) => {
//       const res = await Auth.post(api.GetProduct, body, options);

//       const response = res.data;
//       switch (response.status.toLowerCase()) {
//         case "ok":
//           BOM.LoadTimeout();
//           dispatch({
//             type: cnt.GET_PRODUCT,
//             payload: response.productListing,
//           });
//           break;
//         case "forbidden":
//           BOM.NotifyMsg(cnt.WARNING, response.message);
//           history.push("/sign-in");
//           break;
//         default:
//           BOM.NotifyMsg(cnt.DANGER, response.message);
//           break;
//       }
//     };
//   } catch (error) {
//     console.log("error: ", error);
//     BOM.NotifyMsg(cnt.DANGER, "Something went wrong!");
//   }
// };
// export const GetProductByCoAction = (body) => {
//   // LoadAlert(cnt.LOAD, "Processing");
//   try {
//     return async (dispatch) => {
//       const res = await Auth.post(api.GetProductByCompany, body, options);

//       const response = res.data;
//       switch (response.status.toLowerCase()) {
//         case "ok":
//           BOM.LoadTimeout();
//           dispatch({
//             type: cnt.GET_PRODUCT_BY_COMPANY,
//             payload: response.productByCoData,
//           });
//           break;
//         case "forbidden":
//           BOM.NotifyMsg(cnt.WARNING, response.message);
//           history.push("/sign-in");
//           break;
//         default:
//           BOM.NotifyMsg(cnt.DANGER, response.message);
//           break;
//       }
//     };
//   } catch (error) {
//     console.log("error: ", error);
//     BOM.NotifyMsg(cnt.DANGER, "Something went wrong!");
//   }
// };

// export const GetProductByCaAction = (body) => {
//   // LoadAlert(cnt.LOAD, "Processing");
//   try {
//     return async (dispatch) => {
//       const res = await Auth.post(api.GetProductByCategory, body, options);

//       const response = res.data;
//       switch (response.status.toLowerCase()) {
//         case "ok":
//           BOM.LoadTimeout();
//           dispatch({
//             type: cnt.GET_PRODUCT_BY_CATEGORY,
//             payload: response.productByCaData,
//           });
//           break;
//         case "forbidden":
//           BOM.NotifyMsg(cnt.WARNING, response.message);
//           history.push("/sign-in");
//           break;
//         default:
//           BOM.NotifyMsg(cnt.DANGER, response.message);
//           break;
//       }
//     };
//   } catch (error) {
//     console.log("error: ", error);
//     BOM.NotifyMsg(cnt.DANGER, "Something went wrong!");
//   }
// };

// export const NewProductAction = (body) => {
// //   LoadAlert(cnt.LOAD, "Processing");

//   try {
//     return async () => {
//       const res = await Auth.post(api.NewProduct, body, options);
//       const response = res.data;
//       switch (response.status.toLowerCase()) {
//         case "ok":
//           BOM.NotifyMsg(cnt.SUCCESS, response.message);
//           history.push("/product");
//           break;
//         case "forbidden":
//           BOM.NotifyMsg(cnt.WARNING, response.message);
//           history.push("/sign-in");
//           break;
//         default:
//           BOM.NotifyMsg(cnt.DANGER, response.message);
//           break;
//       }
//     };
//   } catch (error) {
//     console.log("error: ", error);
//     BOM.NotifyMsg(cnt.DANGER, "Something went wrong!");
//   }
// };

// export const EditProductAction = (body) => {
// //   LoadAlert(cnt.LOAD, "Processing");

//   try {
//     return async () => {
//       const res = await Auth.post(api.EditProduct, body, options);
//       const response = res.data;
//       switch (response.status.toLowerCase()) {
//         case "ok":
//           BOM.NotifyMsg(cnt.SUCCESS, response.message);
//           history.push("/product");
//           break;
//         case "forbidden":
//           BOM.NotifyMsg(cnt.WARNING, response.message);
//           history.push("/sign-in");
//           break;
//         default:
//           BOM.NotifyMsg(cnt.DANGER, response.message);
//           break;
//       }
//     };
//   } catch (error) {
//     console.log("error: ", error);
//     BOM.NotifyMsg(cnt.DANGER, "Something went wrong!");
//   }
// };

// export const ProductDetailAction = (selectedObj) => {
//   try {
//     return async (dispatch) => {
//       dispatch({ type: cnt.DETAIL_PRODUCT, payload: selectedObj });
//       history.push("/edit-product");
//     };
//   } catch (error) {
//     console.log("error: ", error);
//     BOM.NotifyMsg(cnt.DANGER, "Something went wrong!");
//   }
// };

// export const DeleteProductAction = (body, label) => {
//   try {
//     return async () => {
//       // confirm
//       Notiflix.Confirm.show(
//         "Delete " + label,
//         "Are you sure?",
//         "Yes",
//         "No",
//         function () {
//           // Yes
//         //   LoadAlert(cnt.LOAD, "Processing");
//           Auth.post(api.DeleteProduct, body, options).then((res) => {
//             const response = res.data;
//             switch (response.status.toLowerCase()) {
//               case "ok":
//                 BOM.NotifyMsg(cnt.SUCCESS, response.message);
//                 history.push("/product");
//                 window.location.reload(false);
//                 break;
//               case "forbidden":
//                 BOM.NotifyMsg(cnt.DANGER, response.message);
//                 history.push("/sign-in");
//                 break;
//               default:
//                 return BOM.NotifyMsg(cnt.DANGER, response.message);
//             }
//           });
//         },
//         function () {
//           // No button
//         }
//       );
//     };
//   } catch (error) {
//     console.log("error: ", error);
//     BOM.NotifyMsg(cnt.DANGER, "Something went wrong!");
//   }
// };

// /** CART */
export const ShoppingCartAction = (selectedObj = {}, action) => {
  // console.log(action);
  try {
    return async (dispatch) => {
      switch (action.toLowerCase()) {
        case "add":
          // console.log(selectedObj);
          BOM.NotifyMsg(
            cnt.SUCCESS,
            selectedObj.PR_NAME + " has been added to cart!"
          );
          dispatch({ type: cnt.ADD_TO_CART, payload: selectedObj });
          break;
        case "add-saved-cart":
          dispatch({ type: cnt.ADD_TO_CART, payload: selectedObj });
          break;
        case "remove":
          BOM.NotifyMsg(
            cnt.SUCCESS,
            selectedObj.PR_NAME + " has been removed from cart!"
          );
          dispatch({
            type: cnt.REMOVE_FROM_CART,
            payload: selectedObj,
          });
          break;
        case "clear":
          history.push("/empty-cart");
          BOM.NotifyMsg(cnt.SUCCESS, "Your Cart has been cleared!");
          dispatch({
            type: cnt.CLEAR_CART,
          });
          break;
        case "complete":
          dispatch({
            type: cnt.COMPLETE_PRODUCT,
            payload: selectedObj,
          });
          break;
        case "incomplete":
          dispatch({
            type: cnt.INCOMPLETE_PRODUCT,
            payload: selectedObj,
          });
          break;
        case "increase":
          dispatch({ type: cnt.INCREASE_QTY, payload: selectedObj });
          break;
        case "decrease":
          dispatch({ type: cnt.DECREASE_QTY, payload: selectedObj });
          break;
        case "shopcount":
          dispatch({ type: cnt.SHOPS_COUNT, payload: selectedObj });
          break;
        case "empty_shop_count":
          dispatch({ type: cnt.EMPTY_SHOPS_COUNT });
          break;
          
      }
    };
  } catch (error) {
    console.log("error: ", error);
    BOM.NotifyMsg(cnt.DANGER, "Something went wrong!");
  }
};

export const SaveCartAction = (body) => {
//   LoadAlert(cnt.LOAD, "Processing");

  try {
    return async (dispatch) => {
      const res = await Auth.post(api.NewCart, body, options);
      const response = res.data;
      switch (response.status.toLowerCase()) {
        case "ok":
          BOM.NotifyMsg(cnt.SUCCESS, response.message);
          history.push("/product");
          dispatch({ type: cnt.CLEAR_CART });
          break;
        case "forbidden":
          BOM.AlertMsg(cnt.WARNING, response.message);
          history.push("/sign-in");
          break;
        default:
          BOM.AlertMsg(cnt.WARNING, response.message);
          break;
      }
    };
  } catch (error) {
    console.log("error: ", error);
    BOM.NotifyMsg(cnt.DANGER, "Something went wrong!");
  }
};
