/**
 * @author Gedeon NdundeCode
 * @date 05/12/2021
 */
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  SignInAction,
  SignOutAction,
} from "../../services/redux/actions/auth-act";
import { cnt } from "../../services/constant.action";
import { BOM } from "../../services/defined.action";
import Footer from "../ui-setup/Footer";
import { apis as api } from "../../services/api.action";
import appLabel from "../../config/appLabel";
import { SubmitBtnLoad } from "../layouts/Facility";
const SignIn = (props) => {
  /** INITIALIZE VARIABLES */
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const { SignInAction, SignOutAction, history } = props;
  const [usernameVal, setUsername] = useState("");
  const [passwordVal, setPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // console.log("this is props: ", props);
    SignOutAction();
  }, []);

  // Password toggle handler
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  /** SUBMISSION */
  const onSignIn_OLD = (username, password) => {
    if (!username || !password) {
      BOM.AlertMsg(cnt.WARNING, "Username and Password are required!");
      return;
    }
    const userData = {
      username,
      password,
    };

    SignInAction(userData);
  };

  /** SUBMISSION */
  const onSignIn = (formData) => {
    const body = {
      ...formData,
    };

    // console.log(body);
    // return;
    setIsLoading(true);

    // return
    BOM.SessionLessReqAction(body, api.Login, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
        setIsLoading(false);
      } else {
        const { userData } = res;

        /** REDIRECT USER */
        switch (userData.USER_STATUS.toLowerCase()) {
          case "new":
            dispatch({
              type: cnt.SIGN_IN,
              payload: userData,
            });
            history.push("/activate-account");
            break;
          case "activated":
            // BOM.AlertMsg(cnt.SUCCESS, "Welcome " + response.userData.USER_FNAME);
            dispatch({
              type: cnt.SIGN_IN,
              payload: userData,
            });

            /** CHECK IF USER CHANGED PASSWORD OR NOT */
            if (userData.USER_DEFAULT_PWD.toLowerCase() !== "edited") {
              history.push("/change-password");
            } else {
              history.push("/company");
              // window.location.reload(false);
            }

            break;
          default:
            history.push("/sign-in");
            break;
        }
      }
    });
  };

  return (
    <div>
      {/* ========== MAIN ========== */}
      <main id="content" role="main">
        {/* Form */}
        <div className="d-flex align-items-center position-relative vh-lg-100">
          <div className="container">
            <div className="row no-gutters">
              <div className="col-md-8 col-lg-7 col-xl-6 offset-md-2 offset-lg-2 offset-xl-3 space-top-3 space-lg-0">
                {/* Form */}
                <form onSubmit={handleSubmit(onSignIn)}>
                  {/* Title */}
                  <div className="mb-5 mb-md-7">
                    <h1 className="h2">Welcome back</h1>
                    <p>Login to manage your account.</p>
                  </div>
                  {/* End Title */}
                  {/* Form Group */}
                  <div className="js-form-message form-group">
                    <label className="input-label" htmlFor="signinSrEmail">
                      Username
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="username"
                      // onChange={(e) => setUsername(e.target.value)}
                      {...register("username", {
                        required: appLabel.fieldReq,
                      })}
                    />
                    <span className="asterisks">
                      {errors?.username?.message}
                    </span>
                  </div>
                  {/* End Form Group */}
                  {/* Form Group */}
                  <div className="js-form-message form-group">
                    <label
                      className="input-label"
                      htmlFor="signinSrPassword"
                      tabIndex={0}
                    >
                      <span className="d-flex justify-content-between align-items-center">
                        Password
                        <Link
                          to="/forgot-password"
                          className="link-underline text-capitalize font-weight-normal"
                        >
                          Forgot Password?
                        </Link>
                      </span>
                    </label>
                    <input
                      type={passwordShown ? "text" : "password"}
                      className="form-control"
                      placeholder="********"
                      // onChange={(e) => setPassword(e.target.value)}
                      {...register("password", {
                        required: appLabel.fieldReq,
                        minLength: {
                          value: 3,
                          message: appLabel.pwdShort,
                        },
                      })}
                    />
                    <span className="p-viewer" onClick={togglePassword}>
                      <i
                        className={
                          passwordShown ? "fas fa-eye-slash" : "fas fa-eye"
                        }
                        aria-hidden="true"
                      ></i>
                    </span>
                    <span className="asterisks">
                      {errors?.password?.message}
                    </span>
                  </div>
                  {/* End Form Group */}
                  {/* Button */}
                  <div className="row align-items-center mb-5">
                    <div className="col-sm-6 mb-3 mb-sm-0">
                      <span className="font-size-1 text-muted">
                        {appLabel.newTo}
                      </span>

                      <Link
                        to={"/sign-up/" + "self-service"}
                        className="font-size-1 font-weight-bold"
                      >
                        {" "}
                        {appLabel.createAccount}
                      </Link>
                    </div>
                    <div className="col-sm-6 text-sm-right">
                      {/* <button
                        type="submit"
                        className="btn btn-primary transition-3d-hover btn-sm"
                      >
                        <i className="fas fa-arrow-circle-right"></i>&nbsp;Login
                      </button> */}
                      <SubmitBtnLoad
                        btnClass={"btn btn-primary btn-pill transition-3d-hover btn-sm"}
                        isLoading={isLoading}
                        btnName={appLabel.btnLogin}
                        icon={"fas fa-arrow-circle-right"}
                      />
                    </div>
                  </div>
                  {/* End Button */}
                </form>
                {/* End Form */}
              </div>
            </div>
          </div>
        </div>
        {/* End Form */}
      </main>
      {/* ========== END MAIN ========== */}
      {/* ========== FOOTER ========== */}
      <Footer />
      {/* ========== END FOOTER ========== */}
    </div>
  );
};

export default connect(null, { SignInAction, SignOutAction })(SignIn);
