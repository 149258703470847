/** CONSTANTS LIST */

//ALERT
// export const WARNING = "WARNING";
// export const SUCCESS = "SUCCESS";
// export const DANGER = "DANGER";
// export const INFO = "INFO";
// export const PROCESS = "PROCESS";
// export const LOAD = "LOAD";

// /** COMPANY */
// export const GET_COMPANY = "GET_COMPANY";
// export const DETAIL_COMPANY = "DETAIL_COMPANY";

// /** CATEGORY */
// export const GET_CATEGORY = "GET_CATEGORY";
// export const DETAIL_CATEGORY = "DETAIL_CATEGORY";

// /** PRODUCT */
// export const GET_PRODUCT = "GET_PRODUCT";
// export const DETAIL_PRODUCT = "DETAIL_PRODUCT";
// export const GET_PRODUCT_BY_COMPANY = "GET_PRODUCT_BY_COMPANY";
// export const GET_PRODUCT_BY_CATEGORY = "GET_PRODUCT_BY_CATEGORY";
// export const ADD_TO_CART = "ADD_TO_CART";
// export const CLEAR_CART = "CLEAR_CART";
// export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
// export const COMPLETE_PRODUCT = "COMPLETE_PRODUCT";

export const cnt = {
  // ALERT
  WARNING: "WARNING",
  SUCCESS: "SUCCESS",
  DANGER: "DANGER",
  INFO: "INFO",
  PROCESS: "PROCESS",
  LOAD: "LOAD",
  COPIED: "COPIED",

  /** AUTH */
  SIGN_IN: "SIGN_IN",
  SIGN_OUT: "SIGN_OUT",
  SIGN_UP: "SIGN_UP",

  /** COMPANY */
  GET_COMPANY: "GET_COMPANY",
  DETAIL_COMPANY: "DETAIL_COMPANY",
  CLEAR_COMPANY_DATA: "CLEAR_COMPANY_DATA",

  /** CATEGORY */
  GET_CATEGORY: "GET_CATEGORY",
  DETAIL_CATEGORY: "DETAIL_CATEGORY",
  CLEAR_CATEGORY_DATA: "CLEAR_CATEGORY_DATA",

  /** PRODUCT */
  GET_PRODUCT: "GET_PRODUCT",
  DETAIL_PRODUCT: "DETAIL_PRODUCT",
  GET_PRODUCT_BY_COMPANY: "GET_PRODUCT_BY_COMPANY",
  GET_PRODUCT_BY_CATEGORY: "GET_PRODUCT_BY_CATEGORY",
  ADD_TO_CART: "ADD_TO_CART",
  CLEAR_CART: "CLEAR_CART",
  REMOVE_FROM_CART: "REMOVE_FROM_CART",
  COMPLETE_PRODUCT: "COMPLETE_PRODUCT",
  CLEAR_PRODUCT_DATA: "CLEAR_PRODUCT_DATA",

  // CART
  INCREASE_QTY: "INCREASE_QTY",
  DECREASE_QTY: "DECREASE_QTY",
  INCOMPLETE_PRODUCT: "INCOMPLETE_PRODUCT",
  SHOPS_COUNT: "SHOPS_COUNT",
  EMPTY_SHOPS_COUNT: "EMPTY_SHOPS_COUNT",

  // STATIC DATA
  RESPONSE_SUCCESS_DATA: "RESPONSE_SUCCESS_DATA",
  RESPONSE_FAILED_DATA: "RESPONSE_FAILED_DATA",
  COUNTRY_DATA: "COUNTRY_DATA",
  CLEAR_STATIC_DATA: "CLEAR_STATIC_DATA",
};
