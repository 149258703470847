/**
 * @author Gedeon NdundeCode
 * @date 06/12/2021
 */
import Auth, { headerOptions as options, apis as api } from "../../api.action";
import { cnt } from "../../constant.action";
import history from "../../../history";
import { BOM } from "../../defined.action";
import { store } from "../store";

/** AUTH LOGIC */
export const SignInAction = (userData) => {
  BOM.LoadAlert(cnt.LOAD, "Processing");
  try {
    return async (dispatch) => {
      const res = await Auth.post(api.Login, userData, options);

      const response = res.data;
      /** OUTER SWITCH */
      switch (response.status.toLowerCase()) {
        case "ok":
          BOM.LoadTimeout();

          /** INNER SWITCH */
          switch (response.userData.USER_STATUS.toLowerCase()) {
            
            case "new":
              // BOM.LoadTimeout();
              dispatch({
                type: cnt.SIGN_IN,
                payload: response.userData,
              });
              history.push("/activate-account");
              break;
            case "activated":
              // BOM.AlertMsg(cnt.SUCCESS, "Welcome " + response.userData.USER_FNAME);
              dispatch({
                type: cnt.SIGN_IN,
                payload: response.userData,
              });

              /** CHECK IF USER CHANGED PASSWORD OR NOT */
              if (
                response.userData.USER_DEFAULT_PWD.toLowerCase() !== "edited"
              ) {
                history.push("/change-password");
              } else {
                history.push("/company");
                window.location.reload(false);
              }

              break;
            default:
              history.push("/sign-in");
              break;
          }
          /** END INNER SWITCH */
          break;

        default:
          BOM.AlertMsg(cnt.DANGER, response.message);
          break;
      }
      /** END OUTER SWITCH */
    };
  } catch (error) {
    console.log("error: ", error);
    BOM.AlertMsg(cnt.DANGER, "Something went wrong!");
  }
};

export const SignUpAction = (userData) => {
  BOM.LoadAlert(cnt.LOAD, "Processing");
  try {
    return async (dispatch) => {
      const res = await Auth.post(api.Signup, userData, options);

      const response = res.data;
      switch (response.status.toLowerCase()) {
        case "ok":
          BOM.AlertMsg(cnt.SUCCESS, response.message);
          dispatch({
            type: cnt.RESPONSE_SUCCESS_DATA,
            payload: response,
          });
          history.push("/sign-in");
          break;

        default:
          BOM.AlertMsg(cnt.DANGER, response.message);
          break;
      }
    };
  } catch (error) {
    console.log("error: ", error);
    BOM.AlertMsg(cnt.DANGER, "Something went wrong!");
  }
};

export const ActivateAccountAction = (userData) => {
  BOM.LoadAlert(cnt.LOAD, "Processing");
  try {
    return async (dispatch) => {
      const res = await Auth.post(api.ActivateAccount, userData, options);

      const response = res.data;
      switch (response.status.toLowerCase()) {
        case "ok":
          BOM.AlertMsg(cnt.SUCCESS, response.message);
          history.push("/company");
          break;

        default:
          BOM.AlertMsg(cnt.DANGER, response.message);
          break;
      }
    };
  } catch (error) {
    console.log("error: ", error);
    BOM.AlertMsg(cnt.DANGER, "Something went wrong!");
  }
};

export const ChangePasswordAction = (userData) => {
  BOM.LoadAlert(cnt.LOAD, "Processing");
  try {
    return async (dispatch) => {
      const res = await Auth.post(api.ChangePassword, userData, options);

      const response = res.data;
      switch (response.status.toLowerCase()) {
        case "ok":
          BOM.AlertMsg(cnt.SUCCESS, response.message);
          history.push("/company");
          break;

        default:
          BOM.AlertMsg(cnt.DANGER, response.message);
          break;
      }
    };
  } catch (error) {
    console.log("error: ", error);
    BOM.AlertMsg(cnt.DANGER, "Something went wrong!");
  }
};

export const ResetPasswordAction = (userData) => {
  BOM.LoadAlert(cnt.LOAD, "Processing");
  try {
    return async (dispatch) => {
      const res = await Auth.post(api.ResetPassword, userData, options);

      const response = res.data;
      switch (response.status.toLowerCase()) {
        case "ok":
          BOM.AlertMsg(cnt.SUCCESS, response.message);
          history.push("/sign-in");
          break;

        default:
          BOM.AlertMsg(cnt.DANGER, response.message);
          break;
      }
    };
  } catch (error) {
    console.log("error: ", error);
    BOM.AlertMsg(cnt.DANGER, "Something went wrong!");
  }
};

export const ForgotPasswordAction = (userData) => {
  BOM.LoadAlert(cnt.LOAD, "Processing");
  try {
    return async (dispatch) => {
      const res = await Auth.post(api.ForgotPassword, userData, options);

      const response = res.data;
      switch (response.status.toLowerCase()) {
        case "ok":
          BOM.AlertMsg(cnt.SUCCESS, response.message);
          history.push("/sign-in");
          break;

        default:
          BOM.AlertMsg(cnt.DANGER, response.message);
          break;
      }
    };
  } catch (error) {
    console.log("error: ", error);
    BOM.AlertMsg(cnt.DANGER, "Something went wrong!");
  }
};

export const SignOutAction = () => {
  return () => {
    // storage.removeItem('persistor:root')

    store.dispatch({ type: cnt.CLEAR_COMPANY_DATA });
    store.dispatch({ type: cnt.CLEAR_CATEGORY_DATA });
    store.dispatch({ type: cnt.CLEAR_PRODUCT_DATA });
    store.dispatch({ type: cnt.CLEAR_CART });
    store.dispatch({ type: cnt.CLEAR_STATIC_DATA });
    store.dispatch({ type: cnt.SIGN_OUT });

    history.push("/sign-in");
  };
};
