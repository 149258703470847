const appLabel = {
  fieldReq: "Field is required.",
  newTo: "New to nHouz?",
  createAccount: "Register Now",
  lockscreen: "Lock Screen",
  pageNotFound:"Page Not Found",
  dashboard:"Dashboard",
  btnLogin: "Login",
  processing:"Processing..",
  noDataAvailableMsg: "There is no data available.",
  noDataForMsg: "There is no data available for ",

  // BUTTON
  createBtn: "Create",
  editBtn: "Edit",
  saveBtn: "Save",
  addtoCartBtn: "Add to Cart",
  addNewBtn: "Add New",
  deleteBtn: "Delete",
  useThisBtn: "Use this",

  //OTHER
  shopName:"Shop name",
  shopInfo:"Shop Information",
  back:"Back",
  description:"Description",
  optional:" (optional)",
  myCart:" My Cart",
  categoryName:"Category Name",
  categoryInfo:"Category Information",
  productName:"Product Name",
  productPrice:"Product Price",
  productInfo:"Product Information",
  saveCart:"Save Cart",
  clearCart:"Clear Cart",
  choose: "Select",
  productList: "Product List",
  continueAdding: "Continue adding",


};

export default appLabel;
