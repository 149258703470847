import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Sidebar from "../ui-setup/Sidebar";
import Header from "../ui-setup/Header";
import { Link } from "react-router-dom";
import Hero from "../ui-setup/Hero";
import Footer from "../ui-setup/Footer";

import { useForm } from "react-hook-form";
import { cnt } from "../../services/constant.action";
import { apis as api } from "../../services/api.action";
import { BOM } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import { SubmitBtnLoad } from "../layouts/Facility";


const NewProduct = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const {
    history,
    userData
  } = props;
  const { PROFILE_ID: profileId, token } = userData;

  const [categoryData, setCategoryData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  /** DEFAULT CALL */
  useEffect(() => {
    getCompany()
    getCategory()
  }, []);

  function getCompany() {

    const body = {
      profileId,
      userToken: token,
    };

    BOM.FetchReqAction(body, api.GetCompany, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objList = res.companyInfoListing;

        setCompanyData(objList);
      }
    });
  }
  function getCategory() {

    const body = {
      profileId,
      userToken: token,
    };

    BOM.FetchReqAction(body, api.GetCategory, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objList = res.categoryListing;

        setCategoryData(objList);
        // setIsLoading(false);
      }
    });
  }
  const formSubmit = (formValues) => {

    setIsLoading(true);
    const body = {
      profileId,
      ...formValues,
      userToken: token,
    };

    // console.log(body);
    // return;

    BOM.FetchReqAction(body, api.NewProduct, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
        setIsLoading(false);
      } else {
        BOM.NotifyMsg(cnt.SUCCESS, res.message);
        history.push("/product");
        setIsLoading(false);
      }
    });
  };

  return (
    <div>
      {/* ========== HEADER ========== */}
      <Header />
      {/* ========== END HEADER ========== */}

      {/* ========== MAIN CONTENT ========== */}
      <main id="content" role="main">
        {/* Hero Section */}
        <Hero />
        {/* <div className="container space-top-1 space-top-md-2 space-bottom-2">
          <div className="row align-items-lg-center">
            <div className="col-lg-5 mb-7 mb-lg-0">
              <div className="mb-4">
                <h1>New Product</h1>
                <p></p>
              </div>
            </div>
            <div className="col-lg-7">
              <img
                className="img-fluid"
                src="../../assets/svg/illustrations/we-are-in-office-1.svg"
                alt="Image Descriptio"
              />
            </div>
          </div>
        </div> */}
        {/* End Hero Section */}
        {/* Apps Section */}
        <div className="container space-bottom-2 space-bottom-lg-3">
          <div className="row">
            <Sidebar />
            <div className="col-lg-9 column-divider-lg">
              <div className="ml-lg-2">
                <form onSubmit={handleSubmit(formSubmit)}>
                  <div className="border-bottom pb-7 mb-7">
                    {/* Title */}
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h3 className="mb-0">{appLabel.productInfo}</h3>
                      <Link
                        to="/product"
                        className="font-size-1 font-weight-bold"
                      >
                        <i className="fas fa-arrow-circle-left fa-sm ml-1" />{" "}
                        {appLabel.back}
                      </Link>
                    </div>
                    {/* End Title */}
                    {/* Product Form */}
                    <div className="row">
                      <div className="col-md-6 mb-3 mb-sm-6">
                        {/* Input */}
                        <div className="js-form-message">
                          <label className="input-label">{appLabel.productName}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={appLabel.productName}
                            {...register("prName", {
                              required: appLabel.fieldReq,
                            })}
                          />
                          <span className="asterisks">
                            {errors?.prName?.message}
                          </span>
                        </div>
                        {/* End Input */}
                      </div>
                      <div className="col-md-6 mb-3 mb-sm-6">
                        {/* Input */}
                        <div className="js-form-message">
                          <label className="input-label">{appLabel.productPrice}</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="0"
                            {...register("prPrice", {
                              required: appLabel.fieldReq,
                            })}
                          />
                           <span className="asterisks">
                            {errors?.prPrice?.message}
                          </span>
                        </div>
                        {/* End Input */}
                      </div>
                      <div className="col-md-6 mb-3 mb-sm-6">
                        {/* Input */}
                        <div className="js-form-message">
                          <label className="input-label">
                            {appLabel.description}{appLabel.optional}
                          </label>
                          {/* <input
                            type="text"
                            className="form-control"
                            onChange={(e) => setProductDesc(e.target.value)}
                            placeholder="Product description"
                          /> */}
                          <textarea
                            className="form-control"
                            rows="4"
                            placeholder={appLabel.description}
                            {...register("prDesc")}
                          ></textarea>
                        </div>
                        {/* End Input */}
                      </div>
                      <div className="col-md-6 mb-3 mb-sm-6">
                        {/* Input */}
                        <div className="js-form-message">
                          <label className="input-label">{appLabel.categoryName}</label>
                          <select
                            className="form-control custom-select"
                            {...register("caId", {
                              required: appLabel.fieldReq,
                            })}
                          >
                            <option value="">{appLabel.choose}</option>
                          {categoryData.map((item, index) => (
                            <option value={item.CA_ID} key={index}>
                              {item.CA_NAME}
                            </option>
                          ))}
                          </select>
                          <span className="asterisks">
                            {errors?.caId?.message}
                          </span>
                        </div>
                        {/* End Input */}
                      </div>
                      <div className="col-md-6 mb-3 mb-sm-6">
                        {/* Input */}
                        <div className="js-form-message">
                          <label className="input-label">{appLabel.shopName}</label>
                          <select
                            className="form-control custom-select"
                            {...register("coId", {
                              required: appLabel.fieldReq,
                            })}
                          >
                            <option value="">{appLabel.choose}</option>
                          {companyData.map((item, index) => (
                            <option value={item.CO_ID} key={index}>
                              {item.CO_NAME}
                            </option>
                          ))}
                          </select>
                          <span className="asterisks">
                            {errors?.coId?.message}
                          </span>
                        </div>
                        {/* End Input */}
                      </div>
                    </div>
                    {/* End Form */}
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                  <SubmitBtnLoad
                      isLoading={isLoading}
                      btnClass={
                        "btn btn-primary btn-pill transition-3d-hover btn-sm"
                      }
                      btnName={appLabel.createBtn}
                      icon={"fas fa-arrow-circle-right fa-sm ml-1"}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* End Apps Section */}
      </main>
      {/* ========== END MAIN CONTENT ========== */}
      {/* ========== FOOTER ========== */}
      <Footer />
      {/* ========== END FOOTER ========== */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NewProduct);