import React, { useEffect } from "react";
import { connect } from "react-redux";

const ResponseSuccess = (props) => {
  const { auth, history, success_res } = props;

  useEffect(() => {
    if (!auth.isSignedIn) {
      history.push("/sign-in");
      return;
    }

    console.log(props);
  }, []);

  return (
    <div>
      {/* ========== MAIN CONTENT ========== */}
      <main id="content" role="main">
        {/* Cart Section */}
        <div className="container space-2 space-lg-3">
          <div className="w-md-80 w-lg-50 text-center mx-md-auto">
            <i className="fas fa-check-circle text-success fa-5x mb-3" />
            <div className="mb-5">
              <h1 className="h2">SUCCESS</h1>
              <p>
                {success_res.message}.!
              </p>
            </div>
            <a
              className="btn btn-primary btn-pill text-white transition-3d-hover px-5"
              onClick={() => history.goBack()}
            >
              OK
            </a>
          </div>
        </div>
        {/* End Cart Section */}
      </main>
      {/* ========== END MAIN CONTENT ========== */}
    </div>
  );
};

const mapStateProps = (state) => {
  return {
    auth: state.auth,
    success_res: state.staticData.success_response,
  };
};
export default connect(mapStateProps, {})(ResponseSuccess);
