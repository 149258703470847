import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import history from "../../history";
import { Link } from "react-router-dom";

const Header = (props) => {
  const { auth } = props;
  const [userData, setUserData] = useState(auth.userData);

  useEffect(() => {
  }, []);
  return (
    <div>
      {/* ========== HEADER ========== */}
      <header id="header" className="header left-aligned-navbar">
        <div className="header-section">
          {/* Topbar */}
          <div className="container">
            <nav className="js-mega-menu navbar navbar-expand-lg z-index-999">
              <div id="topBar" className="collapse navbar-collapse">
                <ul className="navbar-nav w-100">
                  <li className="navbar-nav-item mr-auto">
                    <a
                      className="nav-link font-size-1 py-2 pl-0"
                      href="../landings/index.html"
                    ></a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
          {/* End Topbar */}
          <div id="logoAndNav" className="container mt-lg-n2">
            {/* Nav */}
            <nav className="js-mega-menu navbar navbar-expand-lg">
              <div className="navbar-nav-wrap">
                {/* Logo */}
                <a
                  className="navbar-brand navbar-nav-wrap-brand"
                  style={{fontSize: '26px', fontWeight: 'bold', color:'#0d47a1'}}
                >
                  {/* <img src="../../assets/svg/logos/logo.svg" alt="Logo" /> */}
                  nHouz
                </a>
                {/* End Logo */}
                {/* Secondary Content */}
                <div className="navbar-nav-wrap-content">
                  {/* Account */}
                  <div className="hs-unfold">
                    <a
                      className="js-hs-unfold-invoker rounded-circle"
                      style={{ cursor: "pointer" }}
                      data-hs-unfold-options='{
                "target": "#accountDropdown",
                "type": "css-animation",
                "event": "hover",
                "duration": 50,
                "delay": 0,
                "hideOnScroll": "true"
               }'
                    >
                      <span className="avatar avatar-md avatar-circle">
                        {userData.USER_GENDER.toLowerCase() === "male" ? (
                          <img
                            className="avatar-img"
                            src="../../assets/img/nhouse/profile/male_avatar.png"
                            alt="Image Description"
                          />
                        ) : (
                          <img
                            className="avatar-img"
                            src="../../assets/img/nhouse/profile/female_avatar.png"
                            alt="Image Description"
                          />
                        )}
                      </span>
                    </a>
                    <div
                      id="accountDropdown"
                      className="hs-unfold-content dropdown-menu dropdown-menu-sm-right dropdown-menu-no-border-on-mobile p-0"
                      style={{ minWidth: 245 }}
                    >
                      <div className="card">
                        {/* Header */}
                        <div className="card-header p-4">
                          <a className="media align-items-center" href="#">
                            <div className="avatar mr-3">
                              <img
                                className="avatar-img"
                                src="../../assets/img/nhouse/profile/male_avatar.png"
                                alt="Image Description"
                              />
                            </div>
                            <div className="media-body">
                              <span className="d-block font-weight-bold">
                                {userData.USER_FNAME +
                                  " " +
                                  userData.USER_LNAME}
                              </span>
                              <span className="d-block small text-muted">
                                @{userData.USERNAME}
                              </span>
                            </div>
                          </a>
                        </div>
                        {/* End Header */}
                        {/* Body */}
                        <div className="card-body py-3">
                          <a className="dropdown-item px-0" href="#">
                            <span className="dropdown-item-icon">
                              <i className="fas fa-user" />
                            </span>
                            Account
                          </a>
                          <Link
                            to="/cart"
                            className="dropdown-item px-0"
                            href="#"
                          >
                            <span className="dropdown-item-icon">
                              <i className="fas fa-cart-plus" />
                            </span>
                            cart
                          </Link>
                          <div className="dropdown-divider" />
                          <a className="dropdown-item px-0" href="#">
                            <span className="dropdown-item-icon">
                              <i className="fas fa-question-circle" />
                            </span>
                            Help
                          </a>
                          <Link
                            to="/sign-in"
                            className="dropdown-item px-0"
                            href="#"
                          >
                            <span className="dropdown-item-icon">
                              <i className="fas fa-power-off" />
                            </span>
                            Log out
                          </Link>
                        </div>
                        {/* End Body */}
                      </div>
                    </div>
                  </div>
                  {/* End Account */}
                </div>
                {/* End Secondary Content */}
                {/* Responsive Toggle Button */}
                <button
                  type="button"
                  className="navbar-toggler navbar-nav-wrap-navbar-toggler btn btn-icon btn-sm rounded-circle"
                  aria-label="Toggle navigation"
                  aria-expanded="false"
                  aria-controls="navBar"
                  data-toggle="collapse"
                  data-target="#navBar"
                >
                  <span className="navbar-toggler-default">
                    <svg
                      width={14}
                      height={14}
                      viewBox="0 0 18 18"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill="currentColor"
                        d="M17.4,6.2H0.6C0.3,6.2,0,5.9,0,5.5V4.1c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,5.9,17.7,6.2,17.4,6.2z M17.4,14.1H0.6c-0.3,0-0.6-0.3-0.6-0.7V12c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,13.7,17.7,14.1,17.4,14.1z"
                      />
                    </svg>
                  </span>
                  <span className="navbar-toggler-toggled">
                    <svg
                      width={14}
                      height={14}
                      viewBox="0 0 18 18"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill="currentColor"
                        d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z"
                      />
                    </svg>
                  </span>
                </button>
                {/* End Responsive Toggle Button */}
                {/* Navigation */}
                <div
                  id="navBar"
                  className="navbar-nav-wrap-navbar collapse navbar-collapse"
                >
                  <ul className="navbar-nav">
                    <li className="navbar-nav-item">
                      <Link
                        to="/sign-in"
                        className="font-weight-bold"
                        style={{ color: "black" }}
                      >
                        <i className="fa fa-sign-out-alt   font-size-1 mr-1"></i>
                        Logout
                      </Link>{" "}
                      &nbsp;&nbsp;&nbsp;&nbsp;
                    </li>
                    <li className="navbar-nav-item">
                      <Link
                        to="/cart"
                        className="font-weight-bold"
                        style={{ color: "black" }}
                      >
                        <i className="fa fa-cart-plus  text-success font-size-1 mr-1"></i>
                        Cart
                      </Link>
                    </li>
                  </ul>
                </div>
                {/* End Navigation */}
              </div>
            </nav>
            {/* End Nav */}
          </div>
        </div>
      </header>
      {/* ========== END HEADER ========== */}
    </div>
  );
};

const mapStateProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateProps, {})(Header);
