import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { cnt } from "../../services/constant.action";
import { BOM } from "../../services/defined.action";
import { ResetPasswordAction } from "../../services/redux/actions/auth-act";
import { RmvAccentOnly } from "../../services/defined.action";
import Footer from "../ui-setup/Footer";

const ResetPassword = (props) => {
  /** INITIALIZE VARIABLES */
  const { ResetPasswordAction, match } = props;
  const { id } = match.params;
  const [newPwd, setNewPwd] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [newPwdShown, setNewPwdShown] = useState(false);
  const [confirmPwdShown, setConfirmPwdShown] = useState(false);

  useEffect(() => {}, []);

  /** SUBMISSION */
  const onChangePwd = (newPassword, confirmPassword) => {
    if (newPassword.length < 6) {
      BOM.AlertMsg(cnt.WARNING, "New Password must be at least 6 characters!");
      return;
    }

    if (!newPassword) {
      BOM.AlertMsg(cnt.WARNING, "New Password field is required!");
      return;
    }

    if (!confirmPassword) {
      BOM.AlertMsg(cnt.WARNING, "Confirm Password field is required!!");
      return;
    }

    if (confirmPassword.toLowerCase() !== newPassword.toLowerCase()) {
      BOM.AlertMsg(cnt.WARNING, "New password and confirm password do not match!");
      return;
    }
    ResetPasswordAction({
      profileId: id,
      newPassword,
      confirmPassword,
    });
  };

  // Password toggle handler
  const togglePassword = (key) => {
    switch (key.toLowerCase()) {
      case "new":
        return setNewPwdShown(!newPwdShown);
      case "confirm":
        return setConfirmPwdShown(!confirmPwdShown);
    }
  };

  return (
    <div>
      <div>
        {/* ========== MAIN ========== */}
        <main id="content" role="main">
          {/* Form */}
          <div className="d-flex align-items-center position-relative vh-lg-100">
            <div className="container">
              <div className="row no-gutters">
                <div className="col-md-8 col-lg-7 col-xl-6 offset-md-2 offset-lg-2 offset-xl-3 space-top-3 space-lg-0">
                  {/* Form */}
                  <form onSubmit={(e) => e.preventDefault()}>
                    {/* Title */}
                    <div className="mb-5 mb-md-7">
                      <h1 className="h3">Hi </h1>
                      {/* <h2 className="h4">You're Almost There</h2> */}
                      <p>Please reset your password</p>
                    </div>
                    {/* End Title */}
  
                    {/* Form Group */}
                    <div className="form-group">
                      <label className="input-label">New Password</label>
                      <input
                        type={newPwdShown ? "text" : "password"}
                        className="form-control"
                        placeholder="********"
                        onChange={(e) => setNewPwd(e.target.value)}
                      />
                      <span
                        className="p-viewer"
                        onClick={() => togglePassword("new")}
                      >
                        <i
                          className={
                            newPwdShown ? "fas fa-eye-slash" : "fas fa-eye"
                          }
                          aria-hidden="true"
                        ></i>
                      </span>
                    </div>
                    {/* End Form Group */}
                    {/* Form Group */}
                    <div className="form-group">
                      <label className="input-label">Confirm Password</label>
                      <input
                        type={confirmPwdShown ? "text" : "password"}
                        className="form-control"
                        placeholder="********"
                        onChange={(e) => setConfirmPwd(e.target.value)}
                      />
                      <span
                        className="p-viewer"
                        onClick={() => togglePassword("confirm")}
                      >
                        <i
                          className={
                            confirmPwdShown ? "fas fa-eye-slash" : "fas fa-eye"
                          }
                          aria-hidden="true"
                        ></i>
                      </span>
                    </div>
                    {/* End Form Group */}
                    {/* Button */}
                    <div className="row align-items-left mb-5">
                    <div className="col-sm-6 mb-3 mb-sm-0">
                        <span className="font-size-1 text-muted">
                          Go to
                        </span>
                        <Link
                          to="/sign-in"
                          className="font-size-1 font-weight-bold"
                        >
                          {" "}
                          Login
                        </Link>
                      </div>
                      <div className="col-sm-3 text-sm-right">
                        <button
                          type="submit"
                          className="btn btn-primary transition-3d-hover btn-sm"
                          onClick={() =>
                            onChangePwd(newPwd, confirmPwd)
                          }
                        >
                          <i className="fas fa-arrow-circle-right"></i>
                          &nbsp; NEXT
                        </button>
                      </div>
                    </div>
                    {/* End Button */}
                  </form>
                  {/* End Form */}
                </div>
              </div>
            </div>
          </div>
          {/* End Form */}
        </main>
        {/* ========== END MAIN ========== */}
        {/* ========== FOOTER ========== */}
        <Footer />
        {/* ========== END FOOTER ========== */}
      </div>
    </div>
  );
};

export default connect(null, { ResetPasswordAction })(ResetPassword);
