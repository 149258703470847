import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { cnt } from "../../services/constant.action";
import { BOM } from "../../services/defined.action";
import { ChangePasswordAction } from "../../services/redux/actions/auth-act";
import { RmvAccentOnly } from "../../services/defined.action";

const ChangePassword = (props) => {
  /** INITIALIZE VARIABLES */
  const { ChangePasswordAction, auth, userData, history } = props;

  const [currentPwd, setCurrentPwd] = useState("");
  const [newPwd, setNewPwd] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [currentPwdShown, setCurrentPwdShown] = useState(false);
  const [newPwdShown, setNewPwdShown] = useState(false);
  const [confirmPwdShown, setConfirmPwdShown] = useState(false);

  useEffect(() => {
    if (!auth.isSignedIn) {
      history.push("/sign-in");
      return;
    }
  }, []);

  /** SUBMISSION */
  const onChangePwd = (currentPassword, newPassword, confirmPassword) => {
    if (newPassword.length < 6) {
      BOM.AlertMsg(cnt.WARNING, "New Password must be at least 6 characters!");
      return;
    }
    
    if (!newPassword) {
      BOM.AlertMsg(cnt.WARNING, "New Password field is required!");
      return;
    }

    if (!currentPassword) {
      BOM.AlertMsg(cnt.WARNING, "Current Password field is required!");
      return;
    }

    if (!confirmPassword) {
      BOM.AlertMsg(cnt.WARNING, "Confirm Password field is required!!");
      return;
    }

    if (confirmPassword.toLowerCase() !== newPassword.toLowerCase()) {
      BOM.AlertMsg(cnt.WARNING, "New password and confirm password do not match!");
      return;
    }

    ChangePasswordAction({
      profileId: userData.PROFILE_ID,
      newPassword,
      currentPassword,
      confirmPassword,
      userToken: userData.token,
    });
  };

  // Password toggle handler
  const togglePassword = (key) => {
    switch (key.toLowerCase()) {
      case "current":
        return setCurrentPwdShown(!currentPwdShown);
      case "new":
        return setNewPwdShown(!newPwdShown);
      case "confirm":
        return setConfirmPwdShown(!confirmPwdShown);
    }
  };

  return (
    <div>
      <div>
        {/* ========== MAIN ========== */}
        <main id="content" role="main">
          {/* Form */}
          <div className="d-flex align-items-center position-relative vh-lg-100">
            <div className="container">
              <div className="row no-gutters">
                <div className="col-md-8 col-lg-7 col-xl-6 offset-md-2 offset-lg-2 offset-xl-3 space-top-3 space-lg-0">
                  {/* Form */}
                  <form onSubmit={(e) => e.preventDefault()}>
                    {/* Title */}
                    <div className="mb-5 mb-md-7">
                      <h1 className="h3">Hi {userData.USERNAME}</h1>
                      {/* <h2 className="h4">You're Almost There</h2> */}
                      <p>Please change your password</p>
                    </div>
                    {/* End Title */}
                    {/* Form Group */}
                    <div className="form-group">
                      <label className="input-label">Current Password</label>
                      <input
                        type={currentPwdShown ? "text" : "password"}
                        className="form-control"
                        placeholder="********"
                        onChange={(e) => setCurrentPwd(e.target.value)}
                      />
                      <span className="p-viewer" onClick={()=>togglePassword("current")}>
                        <i
                          className={
                            currentPwdShown ? "fas fa-eye-slash" : "fas fa-eye"
                          }
                          aria-hidden="true"
                        ></i>
                      </span>
                    </div>
                    {/* End Form Group */}
                    {/* Form Group */}
                    <div className="form-group">
                      <label className="input-label">New Password</label>
                      <input
                        type={newPwdShown ? "text" : "password"}
                        className="form-control"
                        placeholder="********"
                        onChange={(e) => setNewPwd(e.target.value)}
                      />
                      <span className="p-viewer" onClick={()=>togglePassword("new")}>
                        <i
                          className={
                            newPwdShown ? "fas fa-eye-slash" : "fas fa-eye"
                          }
                          aria-hidden="true"
                        ></i>
                      </span>
                    </div>
                    {/* End Form Group */}
                    {/* Form Group */}
                    <div className="form-group">
                      <label className="input-label">Confirm Password</label>
                      <input
                        type={confirmPwdShown ? "text" : "password"}
                        className="form-control"
                        placeholder="********"
                        onChange={(e) => setConfirmPwd(e.target.value)}
                      />
                      <span className="p-viewer" onClick={()=>togglePassword("confirm")}>
                        <i
                          className={
                            confirmPwdShown ? "fas fa-eye-slash" : "fas fa-eye"
                          }
                          aria-hidden="true"
                        ></i>
                      </span>
                    </div>
                    {/* End Form Group */}
                    {/* Button */}
                    <div className="row align-items-left mb-5">
                      <div className="col-sm-3 text-sm-right">
                        <button
                          type="submit"
                          className="btn btn-primary transition-3d-hover btn-sm"
                          onClick={() =>
                            onChangePwd(currentPwd, newPwd, confirmPwd)
                          }
                        >
                          <i className="fas fa-arrow-circle-right"></i>
                          &nbsp; NEXT
                        </button>
                      </div>
                    </div>
                    {/* End Button */}
                  </form>
                  {/* End Form */}
                </div>
              </div>
            </div>
          </div>
          {/* End Form */}
        </main>
        {/* ========== END MAIN ========== */}
      </div>
    </div>
  );
};
const mapStateProps = (state) => {
  return { auth: state.auth, userData: state.auth.userData };
};
export default connect(mapStateProps, { ChangePasswordAction })(
  ChangePassword
);
