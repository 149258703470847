import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { cnt } from "../../services/constant.action";
import { BOM } from "../../services/defined.action";
import { ForgotPasswordAction } from "../../services/redux/actions/auth-act";
import { GetCountryAction } from "../../services/redux/actions/staticData-act";
import Footer from "../ui-setup/Footer";

const ForgotPassword = (props) => {
  /** INITIALIZE VARIABLES */
  const [emailVal, setEmail] = useState("");
  const { ForgotPasswordAction} = props;

  useEffect(() => {
    
  }, []);

 

  /** SUBMISSION */
  const onForgot = (email) => {
    if (!email) {
      BOM.AlertMsg(cnt.WARNING, "Email field is required!");
      return;
    }

    ForgotPasswordAction({
      email: BOM.RmvAccentOnly(email)
    });
  };

  return (
    <div>
      <div>
        {/* ========== MAIN ========== */}
        <main id="content" role="main">
          {/* Form */}
          <div className="d-flex align-items-center position-relative vh-lg-100">
            <div className="container">
              <div className="row no-gutters">
                <div className="col-md-8 col-lg-7 col-xl-6 offset-md-2 offset-lg-2 offset-xl-3 space-top-2 space-lg-0">
                  {/* Form */}
                  <form onSubmit={(e) => e.preventDefault()}>
                    {/* Title */}
                    <div className="mb-5 mb-md-7">
                      <h1 className="h2">
                        Welcome to{" "}
                        <span
                          style={{
                            fontSize: "26px",
                            fontWeight: "bold",
                            color: "#0d47a1",
                          }}
                        >
                          nHouz
                        </span>
                      </h1>
                      <p>Fill out the form to forget your password.</p>
                    </div>
                    {/* End Title */}
                    {/* Form Group */}
                    <div className="js-form-message form-group">
                      <label className="input-label" htmlFor="signinSrEmail">
                        Email address
                        <i
                          className="far fa-question-circle text-body ml-1"
                          data-container="body"
                          data-toggle="popover"
                          data-placement="top"
                          data-trigger="hover"
                          title="Why email?"
                          data-content="nHouse will never share your email with anyone else. Your email will be used to send you critical information such as password,  password reset etc... For more information please check nHouse's terms and conditions below."
                        ></i>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email address"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    {/* End Form Group */}

                    {/* Button */}
                    <div className="row align-items-left mb-5">
                    <div className="col-sm-6 mb-3 mb-sm-0">
                        <span className="font-size-1 text-muted">
                          Go back to
                        </span>
                        <Link
                          to="/sign-in"
                          className="font-size-1 font-weight-bold"
                        >
                          {" "}
                          Login
                        </Link>
                      </div>
                      <div className="col-sm-3 text-sm-right">
                        <button
                          type="submit"
                          className="btn btn-primary transition-3d-hover btn-sm"
                          onClick={() =>
                            onForgot(emailVal)
                          }
                        >
                          <i className="fas fa-arrow-circle-right"></i>
                          &nbsp; NEXT
                        </button>
                      </div>
                    </div>
                    {/* End Button */}
                  </form>
                  {/* End Form */}
                </div>
              </div>
            </div>
          </div>
          {/* End Form */}
        </main>
        {/* ========== END MAIN ========== */}
        {/* ========== FOOTER ========== */}
        <Footer />
        {/* ========== END FOOTER ========== */}
      </div>
    </div>
  );
};

export default connect(null, { ForgotPasswordAction})(
  ForgotPassword
);
