import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { cnt } from "../../services/constant.action";
import { BOM } from "../../services/defined.action";
import { SignUpAction } from "../../services/redux/actions/auth-act";
import { GetCountryAction } from "../../services/redux/actions/staticData-act";
import Footer from "../ui-setup/Footer";

const SignUp = (props) => {
  /** INITIALIZE VARIABLES */
  const [showBtn, setShowBtn] = useState(false);
  const [usernameVal, setUsername] = useState("");
  const [emailVal, setEmail] = useState("");
  const [countryId, setCountryId] = useState("");
  const { SignUpAction, GetCountryAction, countryData, match } = props;
  const { ref } = match.params;

  useEffect(() => {
    // console.log(match);
    // console.log(props);
    GetCountryAction({ table_param: "country" });
  }, []);

  /** OPEN NEW TAB FOR TERM */
  const openTermWindow = () => {
    window.open("terms", "_blank");
  };

  /** SUBMISSION */
  const onSignUp = (email, username, countryId) => {
    if (!email) {
      BOM.AlertMsg(cnt.WARNING, "Email field is required!");
      return;
    }

    if (!username) {
      BOM.AlertMsg(cnt.WARNING, "Username field is required!");
      return;
    }

    if (username.length < 3) {
      BOM.AlertMsg(cnt.WARNING, "Username must be more than 3 characters!");
      return;
    }

    if (!countryId) {
      BOM.AlertMsg(cnt.WARNING, "Country field is required!");
      return;
    }

    SignUpAction({
      email: BOM.RmvAccentOnly(email),
      username: BOM.RmvAccentOnly(username),
      sponsorCode: ref,
      countryId,
    });
  };

  const renderCountry = countryData.map((country) => {
    return (
      <option key={country.ID} value={country.ID}>
        {country.COUNTRY_NAME}
      </option>
    );
  });

  return (
    <div>
      <div>
        {/* ========== MAIN ========== */}
        <main id="content" role="main">
          {/* Form */}
          <div className="d-flex align-items-center position-relative vh-lg-100">
            <div className="container">
              <div className="row no-gutters">
                <div className="col-md-8 col-lg-7 col-xl-6 offset-md-2 offset-lg-2 offset-xl-3 space-top-2 space-lg-0">
                  {/* Form */}
                  <form onSubmit={(e) => e.preventDefault()}>
                    {/* Title */}
                    <div className="mb-5 mb-md-7">
                      <h1 className="h2">
                        Welcome to{" "}
                        <span
                          style={{
                            fontSize: "26px",
                            fontWeight: "bold",
                            color: "#0d47a1",
                          }}
                        >
                          nHouz
                        </span>
                      </h1>
                      <p>Fill out the form to get started.</p>
                    </div>
                    {/* End Title */}
                    {/* Form Group */}
                    <div className="js-form-message form-group">
                      <label className="input-label" htmlFor="signinSrEmail">
                        Email address
                        <i
                          className="far fa-question-circle text-body ml-1"
                          data-container="body"
                          data-toggle="popover"
                          data-placement="top"
                          data-trigger="hover"
                          title="Why email?"
                          data-content="nHouse will never share your email with anyone else. Your email will be used to send you critical information such as password,  password reset etc... For more information please check nHouse's terms and conditions below."
                        ></i>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email address"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    {/* End Form Group */}
                    {/* Form Group */}
                    <div className="js-form-message form-group">
                      <label className="input-label" htmlFor="signinSrPassword">
                        Username{" "}
                        <i
                          className="far fa-question-circle text-body ml-1"
                          data-container="body"
                          data-toggle="popover"
                          data-placement="top"
                          data-trigger="hover"
                          title="Why username?"
                          data-content="Username will be used when login and in all correspondence."
                        ></i>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="username"
                        onChange={(e) => setUsername(e.target.value)}
                      />
                    </div>
                    {/* End Form Group */}
                    {/* Form Group */}
                    <div className="js-form-message form-group">
                      <label
                        className="input-label"
                        htmlFor="signinSrConfirmPassword"
                      >
                        Country {""}
                        <i
                          className="far fa-question-circle text-body ml-1"
                          data-container="body"
                          data-toggle="popover"
                          data-placement="top"
                          data-trigger="hover"
                          title="Why country?"
                          data-content="nHouse will use country information in order to determine currency."
                        ></i>
                      </label>
                      <select
                        className="form-control custom-select"
                        onChange={(e) => setCountryId(e.target.value)}
                      >
                        <option value>Choose...</option>
                        {renderCountry}
                      </select>
                    </div>
                    {/* End Form Group */}
                    {/* Checkbox */}
                    <div className="js-form-message mb-5">
                      <div className="custom-control custom-checkbox d-flex align-items-center text-muted">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="termsCheckbox"
                          onChange={(e) => setShowBtn(!showBtn)}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="termsCheckbox"
                        >
                          <small>
                            I agree to the &nbsp;
                            {/* <a
                              style={{ cursor: "pointer" }}
                              onClick={openTermWindow}
                              className="link-underline text-primary"
                            >
                              Terms and Conditions
                            </a> */}
                            <Link to="/terms" className="link-underline text-primary">Terms and Conditions</Link>
                          </small>
                        </label>
                      </div>
                    </div>
                    {/* End Checkbox */}
                    {/* Button */}
                    <div className="row align-items-center mb-5">
                      <div className="col-sm-6 mb-3 mb-sm-0">
                        <span className="font-size-1 text-muted">
                          Already use nHouse?
                        </span>
                        <Link
                          to="/sign-in"
                          className="font-size-1 font-weight-bold"
                        >
                          {" "}
                          Login
                        </Link>
                      </div>
                      <div
                        className="col-sm-6 text-sm-right"
                        style={{ display: showBtn ? "block" : "none" }}
                      >
                        <button
                          type="submit"
                          className="btn btn-primary transition-3d-hover btn-sm"
                          onClick={() =>
                            onSignUp(emailVal, usernameVal, countryId)
                          }
                        >
                          <i className="fas fa-arrow-circle-right"></i>
                          &nbsp;&nbsp;Get Started
                        </button>
                      </div>
                    </div>
                    {/* End Button */}
                  </form>
                  {/* End Form */}
                </div>
              </div>
            </div>
          </div>
          {/* End Form */}
        </main>
        {/* ========== END MAIN ========== */}
        {/* ========== FOOTER ========== */}
        <Footer />
        {/* ========== END FOOTER ========== */}
      </div>
    </div>
  );
};

const mapStateProps = (state) => {
  // console.log(state);
  return {
    countryData: state.staticData.countries,
  };
};
export default connect(mapStateProps, { SignUpAction, GetCountryAction })(
  SignUp
);
