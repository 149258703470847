import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Sidebar from "../ui-setup/Sidebar";
import Header from "../ui-setup/Header";
import Hero from "../ui-setup/Hero";
import Footer from "../ui-setup/Footer";

import { ShoppingCartAction } from "../../services/redux/actions/product-act";
import { cnt } from "../../services/constant.action";
import { apis as api } from "../../services/api.action";
import { BOM } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import Notiflix from "notiflix";
import { handleSkeletonProdFac } from "../layouts/Facility";

const Product = (props) => {
  const { userData, ShoppingCartAction, history } = props;
  const { PROFILE_ID: profileId, token } = userData;
  const [productData, setProductData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getList();
  }, []);

  function getList() {
    // BOM.LoadAlert(cnt.LOAD, "Processing");
    setIsLoading(true);

    const body = {
      profileId,
      userToken: token,
    };

    BOM.FetchReqAction(body, api.GetProduct, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objList = res.productListing;

        setProductData(objList);
        setIsLoading(false);
      }
    });
  }

  const deleteItem = (objParam) => {
    const { PR_ID, PR_NAME } = objParam;

    let body = {
      profileId,
      itemId: PR_ID,
      itemType: "product",
      userToken: token,
    };

    // console.log(body);
    // return

    const statusLabel = appLabel.deleteBtn + " " + PR_NAME;

    Notiflix.Confirm.show(
      "Are you sure ?",
      statusLabel,
      "Yes",
      "No",
      function () {
        // Yes
        BOM.FetchReqAction(body, api.DeleteItem, (err, res) => {
          if (err) {
            BOM.AlertMsg(cnt.DANGER, err);
          } else {
            BOM.NotifyMsg(cnt.SUCCESS, res.message);
            setProductData(productData.filter((item) => item.PR_ID !== PR_ID));
          }
        });
      },
      function () {
        // No button
      }
    );
  };

  const handleRedirectTo = (pathParam, objParam) => {
    history.push(`/${pathParam}`, {
      ggParam: objParam,
    });
  };

  const onAddToCart = (prObj) => {
    // console.log(prObj);
    if (!prObj) return;

    const cart = {
      TRACK_ID: BOM.GenerateTimestamp(),
      CA_NAME: prObj.CA_NAME,
      CO_NAME: prObj.CO_NAME,
      CO_ID: prObj.CO_ID,
      PR_NAME: prObj.PR_NAME,
      PR_PRICE: parseFloat(prObj.PR_PRICE),
      PR_ID: prObj.PR_ID,
      QTY: 1,
      TRACK_STATUS: false,
    };

    // console.log(cart);
    ShoppingCartAction(cart, "add");
  };

  const renderProductList = productData.map((item, index) => {
    return (
      <div className="col-sm-6 col-lg-4 px-2 px-sm-3 mb-3 mb-sm-5" key={index}>
        {/* Product */}
        <div className="card border shadow-none text-center h-100">
          <div className="position-relative">
            {/* <img
              className="card-img-top"
              src="../../assets/img/300x180/img4.jpg"
              alt="Image Description"
            /> */}
            <div className="position-absolute top-0 right-0 pt-3 pr-3">
              {/* <button
                type="button"
                className="btn btn-xs btn-icon btn-outline-secondary rounded-circle"
                data-toggle="tooltip"
                data-placement="top"
                title="Save for later"
              >
                <i className="fas fa-heart"></i>
              </button> */}
            </div>
            <div className="position-absolute top-0 right-0 pt-3 pr-3">
              {/* <button
                type="button"
                className="btn btn-xs btn-icon btn-outline-secondary rounded-circle"
                data-toggle="tooltip"
                data-placement="top"
                title="Save for later"
              >
                <i className="fas fa-heart" />
              </button> */}
            </div>
          </div>
          <div className="card-body pt-4 px-4 pb-0">
            <div className="mb-2">
              <a className="d-inline-block text-body small font-weight-bold mb-1">
                {item.CA_NAME}
              </a>
              <span className="d-block font-size-1">
                <a className="text-inherit">
                  {item.PR_NAME.length > 12
                    ? item.PR_NAME.substring(0, 12) + "..."
                    : item.PR_NAME}
                </a>
              </span>
              <div className="d-block">
                <span className="text-dark font-weight-bold">
                  {userData.CUR_CODE} {item.PR_PRICE}
                </span>
              </div>
            </div>
          </div>
          <div className="card-footer border-0 pt-0 pb-4 px-4">
            <div className="mb-3">
              <a className="d-inline-flex align-items-center small">
                <span className="text-warning font-weight-bold">
                  {item.CO_NAME}
                </span>
              </a>
            </div>
            <button
              type="button"
              onClick={() => onAddToCart(item)}
              className="btn btn-sm btn-outline-primary btn-pill transition-3d-hover"
            >
              Add to Cart
            </button>
          </div>
          <div className="action-icon">
            <i
              className="fas fa-pen-square text-info"
              onClick={() => handleRedirectTo("edit-product", item)}
            />
            <i
              className="fas fa-times-circle text-danger"
              onClick={() => deleteItem(item)}
            />
          </div>
        </div>
        {/* End Product */}
      </div>
    );
  });
  return (
    <div>
      {/* ========== HEADER ========== */}
      <Header />
      {/* ========== END HEADER ========== */}

      {/* ========== MAIN CONTENT ========== */}
      <main id="content" role="main">
        {/* Hero Section */}
        <Hero />
        {/* End Hero Section */}
        {/* Apps Section */}
        <div className="container space-bottom-2 space-bottom-lg-3">
          <div className="row">
            <Sidebar />
            <div className="col-lg-9 column-divider-lg">
              <div className="ml-lg-2">
                {/* Title */}
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h3 className="mb-0">
                    {productData.length > 1 ? "My Products" : "My Product"}
                  </h3>
                  <a className="font-size-1 font-weight-bold">
                    {productData.length > 1
                      ? productData.length + " Items"
                      : productData.length + " Item"}{" "}
                  </a>
                </div>
                {/* End Title */}
                <div className="row mx-n2 mb-7">
                  {/* STATIC ADD CARD */}
                  <div className="col-sm-6 col-md-4 px-2 mb-3">
                    <div className="card card-frame h-100">
                      <Link to="/new-product" className="card-body">
                        <div className="media">
                          <div className="avatar avatar-xs mt-1 mr-3">
                            <i className="fas fa-plus-circle  text-warning specificIcon" />
                          </div>
                          <div className="media-body">
                            <div className="d-flex align-items-center">
                              <span className="d-block text-dark font-weight-bold">
                                {appLabel.addNewBtn}
                              </span>
                            </div>
                            <small className="d-block text-body">&nbsp;</small>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 px-2 mb-3">
                    <div className="card card-frame h-100">
                      <Link to="/cart" className="card-body">
                        <div className="media">
                          <div className="avatar avatar-xs mt-1 mr-3">
                            <i className="fa fa-cart-plus text-success specificIcon" />
                          </div>
                          <div className="media-body">
                            <div className="d-flex align-items-center">
                              <span className="d-block text-dark font-weight-bold">
                                {appLabel.myCart}
                              </span>
                            </div>
                            <small className="d-block text-body">&nbsp;</small>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                {/* Products */}
                <div className="row mx-n2 mx-sm-n3 mb-3">
                  {isLoading ? handleSkeletonProdFac() : renderProductList}
                </div>
                {/* End Products */}
              </div>
            </div>
          </div>
        </div>
        {/* End Apps Section */}
      </main>
      {/* ========== END MAIN CONTENT ========== */}
      {/* ========== FOOTER ========== */}
      <Footer />
      {/* ========== END FOOTER ========== */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
});
const mapDispatchToProps = { ShoppingCartAction };

export default connect(mapStateToProps, mapDispatchToProps)(Product);
