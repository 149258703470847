import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { connect, useDispatch } from "react-redux";
import { ShoppingCartAction } from "../../services/redux/actions/product-act";
import { cnt } from "../../services/constant.action";
import { apis as api } from "../../services/api.action";
import { BOM } from "../../services/defined.action";
import appLabel from "../../config/appLabel";
import Notiflix from "notiflix";
import { AmntFormatFac, handleSkeletonProdFac } from "../layouts/Facility";
import { Link } from "react-router-dom";
import history from "../../history";
const SavedCart = (props) => {
  const { userData, ShoppingCartAction } = props;
  const { PROFILE_ID: profileId, token, CUR_CODE: currency } = userData;
  const [cartData, setCartData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    getList();
  }, []);

  function getList() {
    // BOM.LoadAlert(cnt.LOAD, "Processing");
    setIsLoading(true);

    const body = {
      profileId,
      userToken: token,
    };

    BOM.FetchReqAction(body, api.GetSavedCart, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objList = res.cartListing;

        setCartData(objList);
        setIsLoading(false);
      }
    });
  }
  const deleteItem = (objParam) => {
    const { CART_ID, CART_TOTAL } = objParam;

    let body = {
      profileId,
      itemId: CART_ID,
      itemType: "cart",
      userToken: token,
    };

    // console.log(body);
    // return

    const statusLabel = appLabel.deleteBtn + " " + CART_TOTAL;

    Notiflix.Confirm.show(
      "Are you sure ?",
      statusLabel,
      "Yes",
      "No",
      function () {
        // Yes
        BOM.FetchReqAction(body, api.DeleteItem, (err, res) => {
          if (err) {
            BOM.AlertMsg(cnt.DANGER, err);
          } else {
            BOM.NotifyMsg(cnt.SUCCESS, res.message);
            setCartData(cartData.filter((item) => item.CART_ID !== CART_ID));
          }
        });
      },
      function () {
        // No button
      }
    );
  };

  const handleSkeletonFac = () =>
    Array(4)
      .fill()
      .map((el, elIndex) => (
        <div className="border-top border-bottom py-4 mb-5" key={elIndex}>
          <div className="row align-items-md-center">
            <div className="col-md-7 mb-5">
              <div className="media align-items-center">
                <div className="avatar avatar-circle">
                  <Skeleton circle={true} height={35} width={35} />
                </div>
                <div className="media-body font-size-1 ml-3">
                  <span className="h6">
                    <a href="blog-profile.html">
                      <Skeleton />
                    </a>{" "}
                  </span>
                  <span className="d-block text-muted">
                    <Skeleton />
                    <small>
                      <Skeleton />
                    </small>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <button
                type="button"
                className="btn btn-xs btn-soft-primary font-weight-bold transition-3d-hover py-1 px-2 ml-1"
              >
                <Skeleton height={14} width={20} />
              </button>
              <button
                type="button"
                className="btn btn-xs btn-soft-danger font-weight-bold transition-3d-hover py-1 px-2 ml-1"
              >
                <Skeleton height={14} width={20} />
              </button>
            </div>
          </div>
        </div>
      ));

  const onChooseCart = (param) => {

    // BOM.AlertMsg(cnt.INFO, "Please keep in mind that while reusing your saved cart, the product price may change at the shop. Please double-check prices.");

    Notiflix.Confirm.show(
      "INFO",
      "Please keep in mind that while reusing your saved cart, the product price may change at the shop. Please double-check prices.",
      "Continue",
      "Check price",
      function () {
        // Yes
        

    setIsBtnLoading(true);
    dispatch({
      type: cnt.CLEAR_CART,
    });
    const body = {
      profileId,
      cartId: param.CART_ID,
      userToken: token,
    };
    // console.log(body);
    // return;
    BOM.FetchReqAction(body, api.GetSavedCart, (err, res) => {
      if (err) {
        BOM.AlertMsg(cnt.DANGER, err);
      } else {
        const objDetail = res.cartDetail;
        let temp = [];

        objDetail.CART_ITEMS.forEach((element) => {
            element.TRACK_STATUS = false
            ShoppingCartAction(element, "add-saved-cart");
        });
        setTimeout(() => {
          setIsBtnLoading(false);
          history.push("/cart");
        }, 1000);
      }
    });
      },
      function () {
        // No button
        history.push("/product");
      }
    );
  };
  return (
    <div className="container space-2 space-lg-3">
      <div className="w-lg-100 text-center">
        <div className="w-lg-100">
          <div className="mb-4">
            <h1 className="h6">
              {" "}
              <Link to="/product">
                <i className="fas fa-arrow-circle-left fa-xs mr-1" />
              </Link>{" "}
              {`Your Saved ${cartData.length > 1 ? "Carts" : "Cart"}  ( ${cartData.length} )`}
            </h1>
            <h1 className="h6">
            {isBtnLoading && (
                          <span>
                            <i className="fas fa-spinner fa-spin" /> &nbsp;{" "}
                            {appLabel.processing}
                          </span>
                        )}
            </h1>
          </div>
          {isLoading
            ? handleSkeletonFac()
            : cartData.map((item, index) => (
                <div className="border-top border-bottom py-4 mb-5" key={index}>
                  <div className="row align-items-md-center">
                    <div className="col-md-7 mb-5">
                      <div className="media align-items-center">
                        <div className="avatar avatar-circle">
                          <img
                            className="img-fluid"
                            src="../../assets/svg/illustrations/empty-cart.svg"
                            alt="SVG"
                          />
                        </div>
                        <div className="media-body font-size-1 ml-3">
                          <span className="h6">
                            <a href="blog-profile.html">
                              <AmntFormatFac
                                currency={currency}
                                inputValue={item.CART_TOTAL}
                              />
                            </a>{" "}
                          </span>
                          <span className="d-block text-muted">
                            {item.MAKER_DATE}{" "}
                            <small>
                              <i>{item.MAKER_TIME}</i>
                            </small>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <button
                        onClick={() => onChooseCart(item)}
                        type="button"
                        className="btn btn-xs btn-soft-primary font-weight-bold transition-3d-hover py-1 px-2 ml-1"
                      >
                        {appLabel.useThisBtn}
                      </button>

                      <button
                        type="button"
                        className="btn btn-xs btn-soft-danger font-weight-bold transition-3d-hover py-1 px-2 ml-1"
                      >
                        {/* {appLabel.deleteBtn} */}
                        <i
                          className="fas fa-times-circle text-danger"
                          onClick={() => deleteItem(item)}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
});
const mapDispatchToProps = { ShoppingCartAction };

export default connect(mapStateToProps, mapDispatchToProps)(SavedCart);
